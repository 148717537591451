import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { ControlContainer, FormControl, FormGroupDirective } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@klickdata/core/auth';
import { FormHelper } from '@klickdata/core/form';
import { Media } from '@klickdata/core/media/src/media.model';
import { MessageService } from '@klickdata/core/message';
import { MessageErrorComponent } from '@klickdata/core/message/src/message-error/message-error.component';
import {
    Resource,
    ResourceCategoryService,
    ResourceData,
    ResourceService,
    ResourceSocketService,
    ResourceTypes,
} from '@klickdata/core/resource';
import { ResourceComposerDirective } from '@klickdata/core/resource/src/resource-composer-directive';
import { StartResourceService } from '@klickdata/core/resource/src/start-resource.service';
import { User } from '@klickdata/core/user';
import { DownloadHelperService } from 'apps/klickdata/src/app/shared/dialog/download-pdf-dialog/download-helper.service';
import { BehaviorSubject, Observable, of, takeUntil } from 'rxjs';

@Component({
    selector: 'app-scorm-manager-core',
    templateUrl: './scorm-manager-core.component.html',
    styleUrls: ['./scorm-manager-core.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
})
export class ScormManagerCoreComponent extends ResourceComposerDirective implements OnInit {
    @Output() saving: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() saved: EventEmitter<Resource> = new EventEmitter<Resource>();
    @Output() published: EventEmitter<Resource> = new EventEmitter<Resource>();
    @Input() bottomToolSheet: boolean;
    public status$: BehaviorSubject<'uploaded' | 'parsing' | 'parsed' | 'empty' | 'error'> = new BehaviorSubject<
        'uploaded' | 'parsing' | 'parsed' | 'empty'
    >('empty');
    public scormMedia: Media;
    typeId = ResourceTypes.SCORM;
    @Input() resource: Resource;
    get resourceBuilder() {
        return null;
    }
    constructor(
        protected parentFormDirective: FormGroupDirective,
        protected auth: AuthService,
        protected route: ActivatedRoute,
        protected categoryService: ResourceCategoryService,
        protected messageService: MessageService,
        protected resourceService: ResourceService,
        protected cdRef: ChangeDetectorRef,
        protected dialog: MatDialog,
        protected router: Router,
        protected bottomSheet: MatBottomSheet,
        protected downloadHelper: DownloadHelperService,
        protected resourceSocketService: ResourceSocketService,
        protected startResourceService: StartResourceService
    ) {
        super(resourceService, categoryService, auth, parentFormDirective, messageService, router, bottomSheet);
    }
    ngOnInit(): void {
        super.ngOnInit();
        this.resourceForm.addControl('scorm_uri', new FormControl(''));
        this.getScormStatus();
    }
    public onTabSelectionChange() {}

    private getScormStatus() {
        if (this.resource?.prompt_status === 'ERROR') {
            this.status$.next('error');
            return;
        }
        this.status$.next(this.resource?.isProcessing() ? 'parsing' : this.resource?.scorm ? 'parsed' : 'empty');
    }

    onPlay() {
        this.startResourceService.takeResource(this.resource, new User()).pipe(takeUntil(this.destroy)).subscribe();
    }
    onResourceChanged(created: boolean) {
        this.typeId = this.resource.type_id;
        this.resourceForm.get('scorm_uri').patchValue(this.resource.scorm_uri);
        if (created) {
            this.buildResourceSocket();
        }
        this.getScormStatus();
    }

    onScormUploaded(media: Media) {
        this.resourceForm.get('scorm_uri').patchValue(media.path + '/' + media.filename);
        this.resourceForm.get('title').patchValue(this.resourceForm.value.title ?? media.filename);
        super.performResSubmit({ eager: 'scorm' });
        this.status$.next('uploaded');
    }
    private buildResourceSocket() {
        this.resourceSocketService
            .listenToResourceUpdates({ res_id: this.resource.id })
            .pipe(takeUntil(this.destroy))
            .subscribe((resource) => {
                console.log(resource);
                this.updateResource(resource, true);
                this.getScormStatus();
            });
    }
    onRemove() {
        this.resourceForm.get('scorm_uri').patchValue(null);
        // this.resourceForm.get('scorm_uri').markAsDirty();
        this.scormMedia = null;
        this.status$.next('empty');
    }
    public onSubmit() {
        this.loading.emit(true);
        this.checkSubmitValid();
    }
    checkSubmitValid() {
        if (this.resourceForm.invalid) {
            FormHelper.markForm(this.resourceForm);
            this.openMessage(
                MessageErrorComponent,
                $localize`${FormHelper.formatInvalidForm(this.resourceForm, this.formats)} is missing`
            );

            this.loading.emit(false);
            return of(false);
        }
        super.performResSubmit({ eager: 'scorm' });
        return of(true);
    }
    protected prepareForm(): Observable<ResourceData> {
        const data = this.resource
            ? this.resource.getData(this.resourceForm.value)
            : new Resource(this.resourceForm.value).getData();

        if (this.scormMedia) {
            data.scorm_uri = this.scormMedia.path + '/' + this.scormMedia.filename;
        }
        if (!this.resource?.type_id) {
            data.type_id = ResourceTypes.SCORM_12;
        }

        return of(data);
    }
    public getResKeysValidaty(): { [key: string]: string | boolean }[] {
        return [
            {
                key: 'title',
                title: $localize`SCORM title`,
                hasValidValue: this.resourceForm.get('title').valid,
                mandatory: true,
            },
            {
                key: 'language_id',
                title: $localize`SCORM language`,
                hasValidValue: this.resourceForm.get('language_id').valid,
                mandatory: true,
            },
            {
                key: 'category_ids',
                title: $localize`SCORM categories`,
                hasValidValue: this.resourceForm.get('category_ids').valid,
                mandatory: true,
            },
            {
                key: 'media_id',
                title: $localize`SCORM image`,
                hasValidValue: this.resourceForm.get('media_id').value,
                mandatory: false,
            },
            {
                key: 'tag_ids',
                title: $localize`SCORM tags`,
                hasValidValue: this.resourceForm.value.tag_ids?.length,
                mandatory: false,
            },
            {
                key: 'bullets',
                title: $localize`SCORM Summary`,
                hasValidValue: this.resourceForm.value.bullets,
                mandatory: false,
                info: $localize`The short summary of this SCORM that will be displayed udner the title (max 256 characters).`,
            },
            {
                key: 'description',
                title: $localize`SCORM description`,
                info: $localize`A description of the SCORM that will be visible befor you take the SCORM.`,
                hasValidValue: this.resourceForm.value.description,
                mandatory: false,
            },
            {
                key: 'instructions',
                title: $localize`Other information`,
                hasValidValue: this.resourceForm.value.instructions,
                mandatory: false,
                info: $localize`Other information that is relevant to this SCORM.`,
            },
        ];
    }
}
