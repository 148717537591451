<ng-container *ngIf="data">
    <ng-container [ngSwitch]="data.dataType">
        <ng-container *ngSwitchCase="SideNaveDataTypes.MESSAGE_ACTIONS">
            <app-menu-side-actions-msg
                (onClose)="bottomSheetRef.dismiss($event)"
                [navData]="data"
            ></app-menu-side-actions-msg>
        </ng-container>
        <ng-container *ngSwitchCase="SideNaveDataTypes.ALERT">
            <app-menu-side-alert-msg
                (onClose)="bottomSheetRef.dismiss($event)"
                [alertData]="data"
            ></app-menu-side-alert-msg>
        </ng-container>
        <ng-container *ngSwitchCase="SideNaveDataTypes.ADD_ROLE">
            <app-menu-side-add-role
                (onClose)="bottomSheetRef.dismiss($event)"
                [navData]="data"
            ></app-menu-side-add-role>
        </ng-container>
        <ng-container *ngSwitchCase="SideNaveDataTypes.ADD_EDUCATOR">
            <app-menu-side-add-educator
                (onClose)="bottomSheetRef.dismiss($event)"
                [navData]="data"
            ></app-menu-side-add-educator>
        </ng-container>
        <ng-container *ngSwitchCase="SideNaveDataTypes.AI_GENERATE">
            <app-menu-side-generate-ai
                (onClose)="bottomSheetRef.dismiss($event)"
                [navData]="data"
            ></app-menu-side-generate-ai>
        </ng-container>

        <ng-container *ngSwitchCase="SideNaveDataTypes.DASHBOARD_COLUMN_SETTINGS">
            <app-menu-side-dashboard-column-settings
                (onClose)="bottomSheetRef.dismiss($event)"
                [navData]="data"
            ></app-menu-side-dashboard-column-settings> </ng-container
        ><ng-container *ngSwitchCase="SideNaveDataTypes.MANAGE_WIDGETS">
            <app-menu-side-manage-widgets
                (onClose)="bottomSheetRef.dismiss($event)"
                [navData]="data"
            ></app-menu-side-manage-widgets>
        </ng-container>

        <ng-container *ngSwitchCase="SideNaveDataTypes.ADD_NEXT_CONTACT">
            <app-menu-side-add-next-contact
                (onClose)="bottomSheetRef.dismiss($event)"
                [navData]="data"
            ></app-menu-side-add-next-contact>
        </ng-container>
        <ng-container *ngSwitchCase="SideNaveDataTypes.ADD_HR_NOTE">
            <app-menu-side-add-hr-note
                (onClose)="bottomSheetRef.dismiss($event)"
                [navData]="data"
            ></app-menu-side-add-hr-note>
        </ng-container>
        <ng-container *ngSwitchCase="SideNaveDataTypes.ADD_CUSTOMER">
            <app-menu-side-add-customer
                (onClose)="bottomSheetRef.dismiss($event)"
                [navData]="data"
            ></app-menu-side-add-customer>
        </ng-container>

        <ng-container *ngSwitchCase="SideNaveDataTypes.COURSE_ITEM_DONE_ALL">
            <app-menu-side-course-item-done-all
                (onClose)="bottomSheetRef.dismiss($event)"
                [navData]="data"
            ></app-menu-side-course-item-done-all>
        </ng-container>

        <ng-container *ngSwitchCase="SideNaveDataTypes.COURSE_MATERIAL_ITEM_DONE">
            <app-menu-side-course-material-item-done
                (onClose)="bottomSheetRef.dismiss($event)"
                [navData]="data"
            ></app-menu-side-course-material-item-done>
        </ng-container>
        <ng-container *ngSwitchCase="SideNaveDataTypes.COURSE_ITEM_DONE">
            <app-menu-side-course-item-done
                (onClose)="bottomSheetRef.dismiss($event)"
                [navData]="data"
            ></app-menu-side-course-item-done>
        </ng-container>

        <ng-container *ngSwitchCase="SideNaveDataTypes.ADD_USER">
            <app-menu-side-add-user
                (onClose)="bottomSheetRef.dismiss($event)"
                [navData]="data"
            ></app-menu-side-add-user>
        </ng-container>
        <ng-container *ngSwitchCase="SideNaveDataTypes.SELECT_PERMISSION_LEVEL">
            <app-menu-side-select-permission-level
                (onClose)="bottomSheetRef.dismiss($event)"
                [navData]="data"
            ></app-menu-side-select-permission-level>
        </ng-container>
        <ng-container *ngSwitchCase="SideNaveDataTypes.ADD_TASK">
            <app-menu-side-add-task
                (onClose)="bottomSheetRef.dismiss($event)"
                [navData]="data"
            ></app-menu-side-add-task>
        </ng-container>
        <ng-container *ngSwitchCase="SideNaveDataTypes.DOWNLOAD">
            <app-menu-side-download
                (onClose)="bottomSheetRef.dismiss($event)"
                [navData]="data"
            ></app-menu-side-download>
        </ng-container>

        <ng-container *ngSwitchCase="SideNaveDataTypes.SIGNOFF_FEEDBACK">
            <app-menu-side-signoff-feedback
                (onClose)="bottomSheetRef.dismiss($event)"
                [navData]="data"
            ></app-menu-side-signoff-feedback>
        </ng-container>
        <ng-container *ngSwitchCase="SideNaveDataTypes.RESOURCE_ENROLLMENT_APPROVAL">
            <app-menu-side-resource-enrollment-approve
                (onClose)="bottomSheetRef.dismiss($event)"
                [navData]="data"
            ></app-menu-side-resource-enrollment-approve>
        </ng-container>
        <ng-container *ngSwitchCase="SideNaveDataTypes.EVENT_JOIN">
            <app-menu-side-event-join
                (onClose)="bottomSheetRef.dismiss($event)"
                [navData]="data"
            ></app-menu-side-event-join>
        </ng-container>
        <ng-container *ngSwitchCase="SideNaveDataTypes.RESOURCE_ENROLLMENT_REQUEST">
            <app-menu-side-resource-enrollment-request
                (onClose)="bottomSheetRef.dismiss($event)"
                [navData]="data"
            ></app-menu-side-resource-enrollment-request>
        </ng-container>

        <app-menu-side-course-item-done
            *ngIf="data.dataType === SideNaveDataTypes.COURSE_ITEM_DONE"
            (onClose)="bottomSheetRef.dismiss($event)"
            [navData]="data"
        ></app-menu-side-course-item-done>
        <app-menu-side-user-actions-template
            *ngIf="data.dataType === SideNaveDataTypes.USER_ACTIONS_TEMPLATE"
            (onClose)="bottomSheetRef.dismiss($event)"
            [navData]="data"
        ></app-menu-side-user-actions-template>
        <app-menu-side-cancel-course-play
            *ngIf="data.dataType === SideNaveDataTypes.CANCEL_COURSE_PLAY"
            (onClose)="bottomSheetRef.dismiss($event)"
            [navData]="data"
        ></app-menu-side-cancel-course-play>

        <ng-container *ngSwitchCase="SideNaveDataTypes.SEND_TEXT_BLOCK_MAIL">
            <app-menu-side-mail-text-block
                (onClose)="bottomSheetRef.dismiss($event)"
                [navData]="data"
            ></app-menu-side-mail-text-block>
        </ng-container>
        <ng-container *ngSwitchCase="SideNaveDataTypes.USER_NOTE">
            <app-menu-side-add-note
                (onClose)="bottomSheetRef.dismiss($event)"
                [navData]="data"
            ></app-menu-side-add-note>
        </ng-container>
        <ng-container *ngSwitchCase="SideNaveDataTypes.GENERAL_NOTIFIER">
            <app-menu-side-notifier
                (onClose)="bottomSheetRef.dismiss($event)"
                [navData]="data"
            ></app-menu-side-notifier>
        </ng-container>
        <ng-container *ngSwitchCase="SideNaveDataTypes.AI_PROMPTER">
            <app-menu-side-ai-prompter
                (onClose)="bottomSheetRef.dismiss($event)"
                [navData]="data"
            ></app-menu-side-ai-prompter>
        </ng-container>
        <ng-container *ngSwitchCase="SideNaveDataTypes.VIDEO_TO_QIUZ">
            <app-menu-side-video-to-quiz
                (onClose)="bottomSheetRef.dismiss($event)"
                [navData]="data"
            ></app-menu-side-video-to-quiz>
        </ng-container>
        <ng-container *ngSwitchCase="SideNaveDataTypes.ADD_RESOURCE_MEDIA">
            <app-menu-side-add-media-with-options
                (onClose)="bottomSheetRef.dismiss($event)"
                [navData]="data"
            ></app-menu-side-add-media-with-options>
        </ng-container>
        <ng-container *ngSwitchCase="SideNaveDataTypes.ADD_LINK_MATERIAL">
            <app-menu-side-create-link-material
                (onClose)="bottomSheetRef.dismiss($event)"
                [navData]="data"
            ></app-menu-side-create-link-material>
        </ng-container>
        <ng-container *ngSwitchCase="SideNaveDataTypes.COURSE_REGISTER">
            <app-menu-side-course-register
                (onClose)="bottomSheetRef.dismiss($event)"
                [navData]="data"
            ></app-menu-side-course-register>
        </ng-container>

        <ng-container *ngSwitchCase="SideNaveDataTypes.SEND_MESSAGE">
            <app-menu-side-send-message
                (onClose)="bottomSheetRef.dismiss($event)"
                [navData]="data"
            ></app-menu-side-send-message>
        </ng-container>
        <ng-container *ngSwitchCase="SideNaveDataTypes.CHECK_EFFICIENCY">
            <app-menu-side-res-efficiency
                (onClose)="bottomSheetRef.dismiss($event)"
                [navData]="data"
            ></app-menu-side-res-efficiency>
        </ng-container>
        <ng-container *ngSwitchCase="SideNaveDataTypes.USER_PROGRESS">
            <app-menu-side-log-user-resource-events
                (onClose)="bottomSheetRef.dismiss($event)"
                [navData]="data"
            ></app-menu-side-log-user-resource-events>
        </ng-container>
        <ng-container *ngSwitchCase="SideNaveDataTypes.GENERAL_TEXT_AREA">
            <app-menu-side-text-area-field
                (onClose)="bottomSheetRef.dismiss($event)"
                [navData]="data"
            ></app-menu-side-text-area-field>
        </ng-container>
        <ng-container *ngSwitchCase="SideNaveDataTypes.QUESTION_EXTRA_INFO">
            <app-menu-side-question-extra-info
                (onClose)="bottomSheetRef.dismiss($event)"
                [navData]="data"
            ></app-menu-side-question-extra-info>
        </ng-container>
        <ng-container *ngSwitchCase="SideNaveDataTypes.ADD_RESOURCE_QUESTIONS">
            <app-menu-side-add-resource-questions
                (onClose)="bottomSheetRef.dismiss($event)"
                [navData]="data"
            ></app-menu-side-add-resource-questions>
        </ng-container>

        <ng-container *ngSwitchCase="SideNaveDataTypes.ADD_COURSE_ITEMS">
            <app-menu-side-add-course-items
                (onClose)="bottomSheetRef.dismiss($event)"
                [navData]="data"
            ></app-menu-side-add-course-items>
        </ng-container>

        <ng-container *ngSwitchCase="SideNaveDataTypes.COURSE_REGISTER">
            <app-menu-side-course-register
                (onClose)="bottomSheetRef.dismiss($event)"
                [navData]="data"
            ></app-menu-side-course-register>
        </ng-container>
        <ng-container *ngSwitchCase="SideNaveDataTypes.MEDIA_ATTACHMENT">
            <app-menu-side-display-media
                (onClose)="bottomSheetRef.dismiss($event)"
                [navData]="data"
            ></app-menu-side-display-media>
        </ng-container>
        <ng-container *ngSwitchCase="SideNaveDataTypes.SEND_MAIL">
            <app-menu-side-send-mail
                (onClose)="bottomSheetRef.dismiss($event)"
                [navData]="data"
            ></app-menu-side-send-mail>
        </ng-container>
    </ng-container>
</ng-container>
