import { SelectionModel } from '@angular/cdk/collections';
import { Location } from '@angular/common';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { AlertDialogComponent } from '@klickdata/core/application/src/alert-dialog/alert-dialog.component';
import { InfoDialogComponent } from '@klickdata/core/application/src/info-dialog/info-dialog.component';
import { AuthService } from '@klickdata/core/auth';
import { Customer } from '@klickdata/core/customer';
import {
    MessageDeletedComponent,
    MessageDeletedUndoComponent,
    MessageDuplicatedComponent,
    MessageSavedComponent,
    MessageService,
} from '@klickdata/core/message';
import { MessageErrorComponent } from '@klickdata/core/message/src/message-error/message-error.component';
import { MobileService, SideNaveActionsTypes, SideNaveDataTypes } from '@klickdata/core/mobile';
import { AppScope, Resource, ResourceCategory, ResourceTag, ResourceSocketService } from '@klickdata/core/resource';
import {
    Filter,
    GlobalFilterProperty,
    GlobalFilterPropertyType,
    SelectFilterOption,
    StatusFilterOption,
    TableFilterComponent,
    TableSource,
} from '@klickdata/core/table';
import { User } from '@klickdata/core/user';
import { Utils } from '@klickdata/core/util';
import { DownloadHelperService } from 'apps/klickdata/src/app/shared/dialog/download-pdf-dialog/download-helper.service';
import moment from 'moment';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, first, shareReplay, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ResourcesAssignSheetComponent } from '../../resource-assignment/resource-assign-sheet/resource-assign-sheet.component';
import { ResourceAvailabilitySheetComponent } from '../../resource-assignment/resource-availability-sheet/resource-availability-sheet.component';
import { ResourceCollectSheetComponent } from '../../resource-assignment/resource-collect-sheet/resource-collect-sheet.component';
import { ResourceInviteSheetComponent } from '../../resource-assignment/resource-invite-sheet/resource-invite-sheet.component';
import { ResourcePublicCustomersSheetComponent } from '../../resource-assignment/resource-public-customers-sheet/resource-public-customers-sheet.component';
import { ResourceRecommendSheetComponent } from '../../resource-assignment/resource-recommend-sheet/resource-recommend-sheet.component';
import { ResourceListingSheetComponent } from '../../resource-listing-sheet/resource-listing-sheet.component';
import { ShareService } from '../../share/share.service';
import { ResourceListingService } from '../resource-listing.service';
import { ResourceTableActions, ResourceTypes } from './../../../../core/resource/src/types.enum';
import { ResourceItemTypes } from '@klickdata/core/resource-item';
import { ResourceManadatorySheetComponent } from '../../resource-assignment/resource-manadatory-sheet/resource-manadatory-sheet.component';
import { DialogDisplayImgComponent } from 'apps/klickdata/src/app/shared/dialog/dialog-display-img/dialog-display-img.component';
import { StartResourceService } from '@klickdata/core/resource/src/start-resource.service';
import { ResourceAccessControlSheetComponent } from '../../resource-assignment/resource-access-control-sheet/resource-access-control-sheet.component';

@Component({
    selector: 'app-resource-listing-general',
    templateUrl: './resource-listing-general.component.html',
    styleUrls: ['./resource-listing-general.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [ResourceListingService],
})
export class ResourceListingGeneralComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(TableFilterComponent) filter: TableFilterComponent;
    // @ViewChild('downloadPopup', { static: false }) downloadPopup: TemplateRef<any>;
    @Input() type_scope_id: number;
    @Input() author: boolean;
    @Input() table_columns: string[];
    @Input() stats: boolean;
    @Input() master: boolean;
    @Input() openFilters = true;
    @Input() allowAddingItem: boolean;
    @Input() disableHoverEffect;
    @Input() SelectiveMultiRowsAction = [
        ResourceTableActions.PUBLIC,
        ResourceTableActions.UNPUBLIC,
        ResourceTableActions.UNPUBLISH,
        ResourceTableActions.PUBLISH,
        ResourceTableActions.DELETE,
    ];
    @Input() customer: Customer;
    @Input() tableToolsAction: ResourceTableActions[];
    @Input() tableFilterItems: Filter<string | number>[];
    @Output() onBrowse: EventEmitter<Resource> = new EventEmitter<Resource>();
    @Output() onView: EventEmitter<Resource> = new EventEmitter<Resource>();
    @Output() onAdd: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() onDownload: EventEmitter<Resource> = new EventEmitter<Resource>();
    public dataSource = new TableSource<Resource>();
    public columns: string[];
    public isMobile: boolean;
    public authUser: User;
    public destroy: Subject<boolean> = new Subject<boolean>();
    selection = new SelectionModel<Resource>(true, []);
    public showActionHeader = false;
    public showColumnsFilter = false;
    public showStatusFilter = false;
    public showLangFilter = false;
    public allResourcesArePublished = false;
    public allResourcesArePubliced = false;
    public allResourcesAreNotPublished = false;
    public allResourcesAreNotPubliced = false;
    public shareLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public downloadLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public tableTools: ResourceTableActions[];
    public tableContext: string;
    public isAuPlatform: boolean;
    public isKolResources: boolean;
    public canRestoreResource: boolean;
    ResourceTypes = ResourceTypes;
    AppScope = AppScope;
    GlobalFilterProperty = GlobalFilterProperty;
    GlobalFilterPropertyType = GlobalFilterPropertyType;
    ResourceTableActions = ResourceTableActions;
    public resourceSelectOptions: SelectFilterOption[];
    public permissionTypeOptions: SelectFilterOption[];
    public resourceStatusOptions: StatusFilterOption[];
    public resourcePrioOptions: SelectFilterOption[];
    public expandedRow: Resource | null;
    public customer$: Observable<Customer>;

    constructor(
        protected resourceService: ResourceListingService,
        protected mobile: MobileService,
        protected dialog: MatDialog,
        protected auth: AuthService,
        protected cdRef: ChangeDetectorRef,
        protected bottomSheet: MatBottomSheet,
        protected messageService: MessageService,
        public shareService: ShareService,
        protected router: Router,
        protected resourceSocketService: ResourceSocketService,
        private location: Location,
        protected downloadHelper: DownloadHelperService,
        protected startResourceService: StartResourceService
    ) {
        this.resourcePrioOptions = Utils.getTaskPrioOptions();
        this.resourceSelectOptions = [
            { title: $localize`Public`, value: 'public', icon: 'public' },
            { title: $localize`None Public`, value: 'not_public', icon: 'public_off' },
            {
                title: $localize`Published`,
                value: 'published',
                icon: 'published_with_changes',
                class: 'material-icons-outlined',
            },
            {
                title: $localize`Not on Publish`,
                value: 'not_last_published',
                icon: 'unpublished',
                class: 'material-icons-outlined',
            },
            { title: $localize`Not Published yet`, value: 'not_published', icon: 'unpublished' },
            { title: $localize`Deleted`, value: 'deleted', icon: 'auto_delete' },
            { title: $localize`Expired`, value: 'expired', icon: 'event_busy' },
        ];
        this.resourceStatusOptions = [
            { title: $localize`Done`, value: 'done', color: '#3e5365', icon: 'done_all', selected: true },
            { title: $localize`Ongoing`, value: 'ongoing', color: '#ff9961', icon: 'cached', selected: true },
            {
                title: $localize`Not Started`,
                value: 'not_started',
                color: '#93cbd1',
                icon: 'remove_done',
                selected: true,
            },
            { title: $localize`Overdue`, value: 'overdue', color: '#a7a7a7', icon: 'event_busy', selected: true },
            { title: $localize`Cancelled`, value: 'cancelled', color: '#e44a66', icon: 'cancel', selected: true },
        ];
        this.permissionTypeOptions = [
            {
                value: 'enroll_approval_required',
                title: $localize`Approval for enroll`,
                icon: 'play_circle',
            },
            {
                value: 'mandatory',
                title: $localize`Mandatory`,
                icon: 'priority_high',
            },
            {
                value: 'view',
                title: $localize`Visibility`,
                icon: 'preview',
            },
            {
                value: 'signoff_required',
                title: $localize`Approval /Sign off`,
                icon: 'assignment_turned_in',
            },
        ];
    }

    public ngOnInit() {
        this.mobile
            .isMobile()
            .pipe(takeUntil(this.destroy), shareReplay())
            .subscribe((mobile) => (this.isMobile = mobile));
        this.dataSource.service = this.resourceService;
        const columns = this.table_columns
            ? this.table_columns
            : this.author
            ? ['select', 'columns', 'title', 'created_at', 'updated_at', 'certifier', 'language']
            : this.master
            ? ['select', 'columns', 'title', 'author_name', 'customer_name', 'certifier', 'language']
            : ['select', 'columns', 'title', 'author_name', 'certifier', 'created_at', 'updated_at', 'language'];
        if (this.type_scope_id === AppScope.TEST || this.type_scope_id === AppScope.SURVEY) {
            columns.splice(5, 0, 'question_number');
            this.tableContext = 'testOrSurvey';
        } else {
            const index = columns.indexOf('question_number');
            if (index !== -1) {
                columns.splice(index, 1);
            }
        }
        this.columns = columns;
        if (this.isMobile) {
            this.columns = ['select', 'columns', 'title', 'tags', 'updated_at', 'extra'];
            if (this.type_scope_id === AppScope.MATERIAL) {
                this.columns.splice(5, 0, 'type_id');
            }
        }
        this.tableTools = this.tableToolsAction
            ? this.tableToolsAction
            : [
                  ResourceTableActions.ASSIGN,
                  ResourceTableActions.PUBLIC,
                  ResourceTableActions.PUBLISH,
                  ResourceTableActions.UNPUBLIC,
                  ResourceTableActions.UNPUBLISH,
                  ResourceTableActions.SHARE,
                  ResourceTableActions.DELETE,
                  ResourceTableActions.DUPLICATE,
                  ResourceTableActions.RECOMMEND,
                  ResourceTableActions.COLLECT,
                  ResourceTableActions.ACCESS_CONTROL,
              ];
        this.isAuPlatform = !this.auth.checkPlatform('admin', 'master');
        this.customer$ = this.auth.getCustomer();
    }

    public ngAfterViewInit(): void {
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.dataSource.filter = this.filter;
        this.auth
            .getUser()
            .pipe(first(), takeUntil(this.destroy))
            .subscribe((user) => {
                this.authUser = user;
                this.updatefilters();
            });
        this.selection.changed.pipe(takeUntil(this.destroy)).subscribe((values) => {
            this.showActionHeader = values.source.selected.length > 0;
            let publishedNu = 0;
            let publicNu = 0;
            let notPublishedNu = 0;
            let notPublicNu = 0;
            values.source.selected.forEach((selected) => {
                selected.last_publish ? publishedNu++ : notPublishedNu++;
                selected.public ? publicNu++ : notPublicNu++;
            });
            this.allResourcesArePublished = publishedNu === values.source.selected.length;
            this.allResourcesAreNotPublished = notPublishedNu === values.source.selected.length;
            this.allResourcesArePubliced = publicNu === values.source.selected.length;
            this.allResourcesAreNotPubliced = notPublicNu === values.source.selected.length;
            this.cdRef.markForCheck();
        });
        this.filter.filterChange.pipe(takeUntil(this.destroy)).subscribe((filtersApplied) => {
            this.canRestoreResource =
                filtersApplied.filters.findIndex(
                    (value: Filter<string | number>) => value.property === 'status' && value.items?.includes('deleted')
                ) !== -1;
            this.setExpansionPanelStatus();
            this.cdRef.markForCheck();
        });
        this.listenToNewResource();
    }

    setExpansionPanelStatus() {
        if (!this.openFilters) {
            this.openFilters = this.filter.activeAppliedFilters.length > 0;
        }
    }

    public listenToNewResource() {
        this.resourceSocketService
            .listenToNewResource()
            .pipe(takeUntil(this.destroy))
            .subscribe((resource) => {
                const res = resource;
                // res.loading = true;
                this.dataSource.replace(res);
            });
    }

    sortTagsAlphabetical(tags) {
        return tags.sort((a, b) => a.name.localeCompare(b.name));
    }

    private updatefilters() {
        const appliedFilters = [];
        if (this.tableFilterItems) {
            this.isKolResources =
                this.tableFilterItems.findIndex((fltr) => fltr.property === 'availableForCustomer') !== -1;
            this.tableFilterItems.forEach((filterItem) => {
                appliedFilters.push(
                    new Filter(filterItem.property, filterItem.items),
                    new Filter(
                        'eager',
                        this.type_scope_id === AppScope.SCORM
                            ? ['tags', 'categories', 'scorm', 'user_collaboration', 'managers']
                            : ['tags', 'categories', 'user_collaboration', 'managers']
                    )
                );
            });
        }

        this.filter.createOrUpdateWithoutEmitFilter(
            appliedFilters.length > 0
                ? appliedFilters
                : this.master
                ? this.getMatserFilter()
                : this.authUser.isAdmin()
                ? [
                      new Filter('scope', [this.type_scope_id]),
                      new Filter('assignedOrCustomer', [this.customer?.id || this.authUser.customer_id]),
                  ]
                : [
                      new Filter('scope', [this.type_scope_id]),
                      new Filter('assignedOrCustomer', [this.authUser.customer_id]),
                      new Filter('published', ['1']),
                  ]
        );
    }
    public onCatClick(category: ResourceCategory) {
        this.filter.setActiveFilterWithQuery(GlobalFilterProperty.CATEGORIES, category.title);
    }

    public onTagClick(tag: ResourceTag) {
        this.filter.filterByModel(tag.getFilter());
    }
    public showImgModal(id: any) {
        this.dialog.open(DialogDisplayImgComponent, {
            maxWidth: '60%',
            data: id,
        });
    }

    ngOnChanges() {
        if (this.master && this.filter && this.authUser) {
            this.filter.createOrUpdateFilter(this.getMatserFilter(), [this.customer ? 'master' : 'customer']);
        }
    }
    public view(resource: Resource) {
        this.router.navigate(['/home/dashboard/resource-details/' + resource.id]);
    }
    public play(resource: Resource) {
        // resource.loading = true;
        this.startResourceService
            .takeResource(resource, this.authUser)
            .pipe(takeUntil(this.destroy))
            .subscribe(() => {
                // resource.loading = false;
                this.cdRef.markForCheck();
            });
    }
    private getMatserFilter() {
        return !this.customer
            ? [new Filter('scope', [this.type_scope_id]), new Filter('master', [this.authUser.id])]
            : [new Filter('scope', [this.type_scope_id]), new Filter('customer', [this.customer.id])];
    }
    public functionNotImpl() {
        this.dialog.open(InfoDialogComponent, {
            disableClose: false,
            data: {
                contentBody: $localize`:@@thisFunctionWillSoonWorkGreat! :This function will soon work great! `,
                neutralBtn: $localize`:@@ok:Ok`,
            },
        });
    }
    public remove(resource?: Resource): void {
        if (resource) {
            resource.loading = true;
        }

        this.mobile.updateSideNavSub({
            dataType: SideNaveDataTypes.GENERAL_NOTIFIER,
            data: {
                icon: 'delete',
                title: resource ? $localize`Delete ${resource.title}` : $localize`Delete multiple items`,
                contentBody: $localize`You are about to delete attached resources. Please use caution and make sure that you really intend to delete before confirming delete below.`,
                positiveBtn: $localize`Delete`,
                negativeBtn: $localize`Cancel`,
            },
        });
        this.mobile
            .getSideNavAction()
            .pipe(
                tap((action) => {
                    if (action != SideNaveActionsTypes.POSITIVE && resource) {
                        resource.loading = false;
                        this.cdRef.markForCheck();
                    }
                }),
                filter((action) => action === SideNaveActionsTypes.POSITIVE),
                switchMap((res) => {
                    if (res) {
                        return resource
                            ? this.resourceService.destroy(resource)
                            : this.resourceService.destroyMultipleResources(
                                  this.selection.selected.map((res) => res.id)
                              );
                    }
                })
            )
            .subscribe((action) => {
                if (action) {
                    this.messageService.openMessage(MessageDeletedComponent);
                    const ids = resource ? [resource.id] : this.selection.selected.map((res) => res.id);
                    this.dataSource.removeByIds(ids);
                    this.selection.clear();
                    const ref = this.messageService.openMessage(MessageDeletedUndoComponent);
                    ref.instance.snackBarRef = ref;
                    // Check if user want to undo.
                    ref.onAction()
                        .pipe(takeUntil(this.destroy))
                        .subscribe(() => {
                            this.restoreResource(ids, true);
                        });
                }
            });
    }

    public onAuthorClick(author) {
        this.bottomSheet.open(ResourceListingSheetComponent, {
            data: {
                id: author.id,
                label: author.name,
                scope_id: AppScope.AUTHOR,
            },
            panelClass: 'sheet-wrapper',
        });
    }

    public restoreResource(ids?: number | number[], fromSnackBar?: boolean) {
        this.resourceService
            .restore(ids ? ids : this.selection.selected.map((res) => res.id))
            .pipe(takeUntil(this.destroy))
            .subscribe((items) => {
                this.messageService.openMessage(MessageSavedComponent);
                this.dataSource.refresh();
                if (fromSnackBar) {
                    this.dataSource.add(items);
                }
            });
    }
    updateResourcePrio(resource: Resource, prio: any) {
        this.resourceService
            .update({ id: resource.id, priority: prio }, true)
            .pipe(takeUntil(this.destroy))
            .subscribe((updatedRes) => {
                resource.loading = false;
                resource.priority = updatedRes.priority;
                this.cdRef.markForCheck();
            });
    }
    updateResourceSortingCode(resource: Resource, code: number) {
        this.resourceService
            .update({ id: resource.id, sorting_code: code }, true)
            .pipe(takeUntil(this.destroy))
            .subscribe((updatedRes) => {
                resource.loading = false;
                resource.sorting_code = updatedRes.sorting_code;
                this.cdRef.markForCheck();
            });
    }
    public duplicate(resource: Resource): void {
        this.resourceService
            .duplicate(resource)
            .pipe(takeUntil(this.destroy))
            .subscribe((newResource) => {
                if (newResource) {
                    this.messageService.openMessage(MessageDuplicatedComponent);
                    this.onBrowse.emit(newResource);
                }
            });
    }

    public inviteFriend(resource: Resource) {
        this.bottomSheet.open(ResourceInviteSheetComponent, {
            data: {
                width: !this.isMobile ? '70%' : '100%',
                id: resource.id,
                context: $localize`Invite`,
                contextIcon: 'outgoing_mail',
                title: resource.title,
            },
            panelClass: 'sheet-wrapper',
        });
    }

    public publish(publish: boolean, resource: Resource) {
        this.mobile.updateSideNavSub({
            dataType: SideNaveDataTypes.GENERAL_NOTIFIER,
            data: {
                icon: publish ? 'publish_outline' : 'unpublished',
                iconClass: 'material-icons-outlined',
                title: publish ? $localize`:@@publish:Publish` : $localize`Specify or unpublish`,
                contentBody: $localize`:@@sureUpdatePublication:Are you sure you want to change the status of publication?`,
                positiveBtn: this.authUser.hasPublishPrivilege(resource)
                    ? publish
                        ? $localize`:@@publish:Publish`
                        : $localize`:@@unpublish:Unpublish`
                    : null,
                negativeBtn: $localize`:@@cancel:Cancel`,
                customBtn: $localize`Specify`,
            },
        });

        this.mobile
            .getSideNavAction()
            .pipe(
                takeUntil(this.destroy),
                switchMap((sideNavActionType: SideNaveActionsTypes) => {
                    if (sideNavActionType === SideNaveActionsTypes.POSITIVE) {
                        return publish
                            ? this.resourceService.publish(resource.id)
                            : this.resourceService.unpublish(resource.id);
                    } else if (sideNavActionType === SideNaveActionsTypes.CUSTOM) {
                        this.authorizeUsers(resource);
                    }
                })
            )
            .subscribe(
                (data: Resource) => {
                    if (data) {
                        this.dataSource.replace(data);
                        data.user_collaboration = resource.user_collaboration;
                        this.messageService.openMessage(MessageSavedComponent);
                        this.cdRef.markForCheck();
                    }
                    this.selection.clear();
                },
                (err) => {
                    if (err && err.error && err.error.error) {
                        this.messageService.openMessage(MessageErrorComponent, err.error.error.messages.join('/n'));
                    }
                }
            );
    }
    authorizeUsers(resource: Resource) {
        this.bottomSheet
            .open(ResourceAccessControlSheetComponent, {
                data: {
                    context: 'access_control',
                    contextTitle: $localize`Access Control`,
                    contextIcon: 'manage_accounts',
                    resource: resource,
                    title: resource.title,
                    user: this.authUser,
                },
                panelClass: 'sheet-wrapper',
            })
            .afterDismissed()
            .pipe(
                takeUntil(this.destroy),
                filter((result) => !!result)
            )
            .subscribe((res: Resource) => {
                resource.user_collaboration = res.user_collaboration;
                this.messageService.openMessage(MessageSavedComponent);
            });
    }
    public createCourse() {
        this.mobile.updateSideNavSub({
            dataType: SideNaveDataTypes.CREATE_RESOURCE,
            data: {
                typeId: ResourceTypes.GeneralCoursePlan,
                items: this.selection.selected.map((res) => {
                    return {
                        name: res.title,
                        item_type_value: this.getItemType(res.type_id),
                        child_resource_id: res.id,
                    };
                }),
            },
        });
        this.selection.clear();
    }
    private getItemType(type: ResourceTypes): ResourceItemTypes {
        switch (ResourceTypes.parentType(type)) {
            case ResourceTypes.TEST:
                return ResourceItemTypes.TEST;
            case ResourceTypes.SURVEY:
                return ResourceItemTypes.SURVEY;
            case ResourceTypes.MATERIAL:
                return ResourceItemTypes.MATERIAL;
            case ResourceTypes.E_COURSE:
                return ResourceItemTypes.E_COURSE;
        }
    }
    public public(makePublic: boolean, resource?: Resource) {
        if (!resource && !this.selection.selected.every((res) => res.last_publish)) {
            this.mobile.updateSideNavSub({
                dataType: SideNaveDataTypes.GENERAL_NOTIFIER,
                data: {
                    icon: 'public',
                    iconClass: 'material-icons-outlined',
                    title: $localize`:@@makePublic:Make public`,
                    contentBody: $localize`You can't make ${AppScope.label(
                        this.type_scope_id,
                        !resource
                    )} public until you publish it first.`,
                    positiveBtn: $localize`:@@publish:Publish`,
                    negativeBtn: $localize`:@@cancel:Cancel`,
                },
            });
            this.mobile
                .getSideNavAction()
                .pipe(
                    takeUntil(this.destroy),
                    switchMap((sideNavActionType: SideNaveActionsTypes) => {
                        if (sideNavActionType === SideNaveActionsTypes.POSITIVE) {
                            return this.resourceService.publish(
                                this.selection.selected.map((res) => res.id),
                                'user_collaboration'
                            );
                        }
                    })
                )
                .subscribe(
                    (data) => {
                        if (data) {
                            this.dataSource.replace(data);
                            this.messageService.openMessage(MessageSavedComponent);
                        }
                        this.selection.clear();
                    },
                    (err) => {
                        if (err && err.error && err.error.error) {
                            this.messageService.openMessage(MessageErrorComponent, err.error.error.messages.join('/n'));
                        }
                    }
                );
        } else {
            this.mobile.updateSideNavSub({
                dataType: SideNaveDataTypes.GENERAL_NOTIFIER,
                data: {
                    icon: makePublic ? 'public' : 'public_off',
                    iconClass: 'material-icons-outlined',
                    title: makePublic
                        ? $localize`:@@makePublic:Make public`
                        : $localize`:@@specifyOrMakeUnpublic:Specify or make unpublic`,
                    contentBody: $localize`Are you sure you want to change the public status?`,
                    positiveBtn: makePublic
                        ? $localize`:@@makePublic:Make public`
                        : $localize`:@@makeUnpublic:Make unpublic`,
                    customBtn: $localize`Specify`,
                },
            });

            this.mobile
                .getSideNavAction()
                .pipe(
                    takeUntil(this.destroy),
                    switchMap((sideNavActionType: SideNaveActionsTypes) => {
                        const ids = resource ? [resource.id] : this.selection.selected.map((res) => res.id);
                        if (sideNavActionType === SideNaveActionsTypes.POSITIVE) {
                            return makePublic ? this.resourceService.public(ids) : this.resourceService.unpublic(ids);
                        } else if (sideNavActionType === SideNaveActionsTypes.CUSTOM) {
                            return this.resourceService.public(ids, { cluster: true }).pipe(
                                switchMap(() => {
                                    return this.bottomSheet
                                        .open(ResourcePublicCustomersSheetComponent, {
                                            data: {
                                                context: makePublic ? 'public' : 'unpublic',
                                                contextTitle: makePublic
                                                    ? $localize`:@@makePublic:Make public`
                                                    : $localize`:@@makeUnPublic:Make Unpublic`,
                                                contextIcon: makePublic ? 'public' : 'public_off',
                                                id: resource.id,
                                                title: resource ? resource.title : $localize`Selected items`,
                                            },
                                            panelClass: 'sheet-wrapper',
                                        })
                                        .afterDismissed()
                                        .pipe(
                                            takeUntil(this.destroy),
                                            filter((result) => !!result)
                                        );
                                })
                            );
                        }
                    })
                )
                .subscribe(
                    (data) => {
                        if (data) {
                            this.dataSource.replace(data);
                            this.messageService.openMessage(MessageSavedComponent);
                        }
                        this.selection.clear();
                    },
                    (err) => {
                        if (err && err.error && err.error.error) {
                            this.messageService.openMessage(MessageErrorComponent, err.error.error.messages.join('/n'));
                        }
                    }
                );
        }
    }
    public publishMultiple(publish: boolean, resource?: Resource) {
        if (resource) {
            resource.loading = true;
        }
        this.dialog
            .open(AlertDialogComponent, {
                disableClose: true,
                data: {
                    icon: publish ? 'publish_outline' : 'unpublished',
                    iconClass: 'material-icons-outlined',
                    title: publish ? $localize`:@@publish:Publish` : $localize`:@@unpublish:Unpublish`,
                    contentBody: $localize`:@@sureUpdatePublication:Are you sure you want to change the status of publication?`,
                    positiveBtn: publish ? $localize`:@@publish:Publish` : $localize`:@@unpublish:Unpublish`,
                    negativeBtn: $localize`:@@cancel:Cancel`,
                },
            })
            .afterClosed()
            .pipe(
                takeUntil(this.destroy),
                tap((result) => {
                    if (!result) {
                        resource.loading = false;
                        this.cdRef.markForCheck();
                    }
                }),
                filter((result) => !!result),
                switchMap(() => {
                    const ids = resource ? [resource.id] : this.selection.selected.map((res) => res.id);
                    return publish
                        ? this.resourceService.publish(ids, 'user_collaboration')
                        : this.resourceService.unpublish(ids, 'user_collaboration');
                })
            )
            .subscribe(
                (data) => {
                    this.dataSource.replace(data);
                    this.selection.clear();
                    this.messageService.openMessage(MessageSavedComponent);
                },
                (err) => {
                    if (err && err.error && err.error.error) {
                        this.messageService.openMessage(MessageErrorComponent, err.error.error.messages.join('/n'));
                    }
                }
            );
    }

    public specifyPublish(resource: Resource) {
        this.bottomSheet
            .open(ResourceAvailabilitySheetComponent, {
                data: {
                    context: 'publish',
                    contextTitle: $localize`:@@specifyPublish:Specify Publish`,
                    contextIcon: 'publish_outline',
                    resource: resource,
                    title: resource ? resource.title : $localize`Selected items`,
                },
                panelClass: 'sheet-wrapper',
            })
            .afterDismissed()
            .pipe(
                takeUntil(this.destroy),
                filter((result) => !!result)
            )
            .subscribe((res) => {
                this.dataSource.replace(res);
            });
    }
    public makeResMandatory(resource: Resource) {
        this.bottomSheet
            .open(ResourceManadatorySheetComponent, {
                data: {
                    context: 'mandatory',
                    contextTitle: $localize`:@@makeMandatory:Make mandatory`,
                    contextIcon: 'gpp_good',
                    resource: resource,
                    title: resource ? resource.title : $localize`Selected items`,
                },
                panelClass: 'sheet-wrapper',
            })
            .afterDismissed()
            .pipe(
                takeUntil(this.destroy),
                filter((result) => !!result)
            )
            .subscribe((res) => {
                this.dataSource.replace(res);
            });
    }
    public specifyPublic(resource: Resource) {
        this.bottomSheet
            .open(ResourcePublicCustomersSheetComponent, {
                data: {
                    context: 'public',
                    contextTitle: $localize`:@@specifyPublic:Specify public`,
                    contextIcon: 'public',
                    id: resource.id,
                    title: resource.title,
                },
                panelClass: 'sheet-wrapper',
            })
            .afterDismissed()
            .pipe(
                takeUntil(this.destroy),
                filter((result) => !!result)
            )
            .subscribe((res) => {
                this.dataSource.replace(res);
            });
    }

    public showCollections(resource?: Resource): void {
        if (resource) {
            this.bottomSheet.open(ResourceCollectSheetComponent, {
                data: {
                    context: $localize`Collect`,
                    contextIcon: 'source',
                    id: resource.id,
                    title: resource.title,
                },
                panelClass: 'sheet-wrapper',
            });
        } else {
        }
    }
    public showRecommends(resource?: Resource): void {
        if (resource) {
            this.bottomSheet.open(ResourceRecommendSheetComponent, {
                data: {
                    context: $localize`Recommend`,
                    contextIcon: 'recommend',
                    id: resource.id,
                    title: resource.title,
                },
                panelClass: 'sheet-wrapper',
            });
        } else {
        }
    }

    public sendCoursePlan(resource: Resource): void {
        this.bottomSheet
            .open(ResourcesAssignSheetComponent, {
                data: {
                    width: !this.isMobile ? '70%' : '100%',
                    id: resource.id,
                    context: resource.assign_id ? $localize`Edit assign` : $localize`Assign`,
                    contextIcon: 'assignment_ind',
                    title: resource.title,
                    referenceIdKey: 'resource_id',
                    referenceFilter: 'assignedResource',
                    assign_id: resource.assign_id,
                },
                panelClass: 'sheet-wrapper',
            })
            .afterDismissed()
            .pipe(
                takeUntil(this.destroy),
                filter((result) => !!result)
            )
            .subscribe((value) => {
                resource.assign_id = value;
                this.dataSource.replace(resource);
            });
    }

    public browse(resource: Resource): void {
        this.onBrowse.emit(resource);
    }

    public add(): void {
        this.onAdd.emit(true);
    }

    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
    }

    selected: Resource;
    downloadMany(resource?: Resource) {
        this.selected = resource;
        this.mobile.updateSideNavSub({
            dataType: SideNaveDataTypes.DOWNLOAD,
            data: {
                data: Utils.getDownloadOptions('material'),
                returnValue: true,
            },
        });
        this.mobile
            .getSideNavAction()
            .pipe(
                filter((action) => action === SideNaveActionsTypes.POSITIVE),
                switchMap(() => this.mobile.getSideNavResponseData()),
                takeUntil(this.destroy)
            )
            .subscribe((res) => {
                if (!Utils.isEmpty(res?.value)) {
                    this.downloadLoading.next(true);

                    this.resourceService
                        .downlaodRes(this.selectedTextMaterialIds, res.value.join())
                        .subscribe((res) => {
                            const file = new Blob([res], { type: res.type });
                            const fileUrl = URL.createObjectURL(file);
                            const anchor = document.createElement('a');
                            anchor.href = fileUrl;
                            anchor.download = `KLMS_${
                                this.selected
                                    ? ResourceTypes.label(this.selected.type_id, {
                                          capitalize: false,
                                      })
                                    : 'resources'
                            }_${moment().format('yMD_hms')}`;
                            this.downloadLoading.next(false);
                            anchor.click();
                            this.selected = null;
                        });
                }
            });
    }

    get selectedTextMaterialIds() {
        return this.selected
            ? [this.selected.id]
            : this.selection.selected.filter((res) => res.type_id === ResourceTypes.TextMaterial).map((res) => res.id);
    }
    public download(resource: Resource) {
        this.downloadHelper.download(resource.downloads);
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle() {
        this.isAllSelected()
            ? this.selection.clear()
            : this.dataSource.data.forEach((row) => this.selection.select(row));
    }

    /** The label for the checkbox on the passed row */
    checkboxLabel(row?: Resource): string {
        if (!row) {
            return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
        }
        return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
    }
    public onRowClicked(resource: Resource) {
        if (this.disableHoverEffect) {
            this.onBrowse.emit(resource);
        }
    }

    public isChecked() {}
    public isIndeterminate() {}
    public selectToggle(res: Resource) {}
    public modelChecked(res: Resource) {}

    ngOnDestroy() {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
