import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute, NavigationStart, ParamMap, Router } from '@angular/router';
import { AuthService } from '@klickdata/core/auth';
import { Customer } from '@klickdata/core/customer/src/customer.model';
import { SideNaveDataTypes } from '@klickdata/core/mobile';
import { ResourceOpportunity } from '@klickdata/core/opportunity/src/resource-opportunity.model';
import { Question, QuestionService, QuestionTypes } from '@klickdata/core/question';
import { Resource, TestPassingPercentage } from '@klickdata/core/resource/src/resource.model';
import { User, UserService } from '@klickdata/core/user';
import { Utils } from '@klickdata/core/util';
import { ExitService } from '@klickdata/player/src/core/exit/exit.service';
import { OccasionService } from '@klickdata/player/src/core/occasion/occasion.service';
import {
    PlayerService,
    TakeTestStatus,
    TestClosingData
} from '@klickdata/player/src/core/player/player.service';
import { DownloadHelperService } from 'apps/klickdata/src/app/shared/dialog/download-pdf-dialog/download-helper.service';
import { LocalStorageService } from 'ngx-webstorage';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { filter, first, map, shareReplay, switchMap, takeUntil } from 'rxjs/operators';
@Component({
    selector: 'app-general-test-results',
    templateUrl: './general-test-results.component.html',
    styleUrls: ['./general-test-results.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GeneralTestResultsComponent implements OnInit, OnDestroy, OnChanges {
    @Input() user: User;
    @Input() isCourseItem: boolean;
    @Input() resource: Resource;
    @Input() occasion: ResourceOpportunity;
    @Input() occasionId: number;
    @Input() tabsVisisbility: boolean[] = [true, true, true];
    public viewMode$: BehaviorSubject<
        'correct_radio' | 'correct_checkbox' | 'input' | 'correct' | 'incorrect' | 'all'
    > = new BehaviorSubject<'correct_radio' | 'correct_checkbox' | 'correct' | 'input' | 'incorrect' | 'all'>('all');
    index = 2;
    maxTotalPoints = 0;
    userTotalPoints = 0;
    view: [number, number] = [150, 150];
    authUser$: Observable<User>;
    showLegend = false;
    showLabels = false;
    explodeSlices = false;
    doughnut = false;
    minPoints = 75;
    userPercent = 0;
    resultsObs: Observable<Question[]>;
    QuestionTypes = QuestionTypes;
    availableQuestionTypes: QuestionTypes[];
    public testPassingPercentage: TestPassingPercentage;
    private destroy: Subject<boolean> = new Subject<boolean>();
    @Output()
    public close: EventEmitter<TestClosingData> = new EventEmitter<TestClosingData>();
    protected updateSubject: BehaviorSubject<boolean> = new BehaviorSubject(true);
    public user$: Observable<User>;
    public questionsActive = true;
    public customer: Observable<Customer>;
    public customerLogotype: Observable<any>;
    public openAll = 0;
    public multiExpand = false;
    private takeTestStatus: TakeTestStatus;
    empty = () => '';

    constructor(
        protected playerService: PlayerService,
        protected questionService: QuestionService,
        protected occasionService: OccasionService,
        protected route: ActivatedRoute,
        protected auth: AuthService,
        protected router: Router,
        protected changeRef: ChangeDetectorRef,
        protected userService: UserService,
        protected dialog: MatDialog,
        protected downloadHelper: DownloadHelperService,
        protected exitService: ExitService,
        protected storage: LocalStorageService
    ) {
        router.events
            .pipe(
                takeUntil(this.destroy),
                filter((event: NavigationStart) => event.navigationTrigger === 'popstate')
            )
            .subscribe(() => {
                if (!this.isCourseItem) {
                    this.exitService.exit();
                }
            });
    }

    ngOnInit() {
        this.authUser$ = this.auth.getUser().pipe(takeUntil(this.destroy), first());
        this.route.queryParamMap
            .pipe(
                takeUntil(this.destroy),
                switchMap((params: ParamMap) => {
                    if (params.has('referrer')) {
                        this.playerService.referrer = params.get('referrer');
                    }
                    return of(null);
                })
            )
            .subscribe();

        this.resultsObs = this.updateSubject.asObservable().pipe(
            switchMap(() => this.questionService.getQuestionsWithOccasion(this.occasion?.id ?? this.occasionId)),
            map(({ questions, passingPercentage }) => {
                // if (!!passingPercentage?.percentage) {
                //     this.testPassingPercentage = passingPercentage;
                //     this.caculateTestRepeat();
                // } else {
                //     this.takeTestStatus = TakeTestStatus.CONTINUE;
                //     this.updateStoredPassingLevel(TakeTestStatus.CONTINUE);
                // }

                this.availableQuestionTypes = this.getAvailableTogglers(questions);
                const data = questions.map((item) => {
                    item.resultStyle = Utils.getQsStyle(item);
                    this.maxTotalPoints += item.max_points;
                    this.userTotalPoints += item.user_points;
                    return item;
                });
                this.userPercent = Math.round((this.userTotalPoints / this.maxTotalPoints) * 100);
                return data;
            })
        );
    }
    public ngOnChanges(changes: SimpleChanges): void {
        if (this.occasionId && !this.occasion) {
            this.occasionService
                .getOccasion(this.occasionId)
                .pipe(takeUntil(this.destroy))
                .subscribe((occ) => {
                    this.occasion = occ;
                    this.user$ = this.userService.getUser(this.occasion.user_id).pipe(shareReplay());
                    this.changeRef.markForCheck();
                });
        } else if (this.occasion) {
            this.user$ = this.userService.getUser(this.occasion.user_id).pipe(shareReplay());
        }
        if (changes.occasion?.currentValue != changes.occasion?.previousValue) {
            this.updateSubject.next(true);
        }
    }
    public calculatePoints(questions: Question[], question: Question) {
        this.userTotalPoints = questions
            .map((qs) => (qs.id == question.id ? question : qs))
            .map((qs) => qs.user_points)
            .reduce((accumulator, currentValue) => {
                return accumulator + currentValue;
            }, 0);
        this.userPercent = Math.round((this.userTotalPoints / this.maxTotalPoints) * 100);
        this.changeRef.markForCheck();
    }
    public onSelectChange(ev: MatTabChangeEvent) {
        this.questionsActive = ev.index === 1;
    }
    public reportQs(qs) {
        this.playerService.updateSliderData({
            dataType: SideNaveDataTypes.ALERT,
        });
        this.playerService.changeSliderStatus(true);
    }

    get occId(): number {
        return this.occasionId ?? this.occasion?.id;
    }
    public exit() {
        this.close.emit({
            status: true,
            testPassingPercentage: this.testPassingPercentage,
            takeTestStatus: this.takeTestStatus,
        });
    }
    public closeSingleTest() {
        this.exitService.exit();
    }
    public open() {
        if (this.openAll === 1) {
            this.openAll = 0;
        } else {
            this.openAll = 1;
        }
        this.multiExpand = true;
        this.changeRef.detectChanges();
    }

    public filteredQuestionData(questions: Question[]): Question[] {
        switch (this.viewMode$.value) {
            case 'all':
                return questions;
            case 'correct_radio':
                return questions.filter((qs) => qs.user_answer && qs.question_type_value === QuestionTypes.RADIO);
            case 'correct_checkbox':
                return questions.filter((qs) => qs.user_answer && qs.question_type_value === QuestionTypes.CHECKBOX);
            case 'input':
                return questions.filter((qs) => qs.question_type_value === QuestionTypes.INPUT);
            case 'incorrect':
                return questions.filter((qs) => !qs.user_answer && qs.question_type_value != QuestionTypes.INPUT);
            case 'correct':
                return questions.filter(
                    (qs) => qs.user_answer || (qs.question_type_value == QuestionTypes.INPUT && qs.metadata?.correct)
                );
        }
    }
    private getAvailableTogglers(questions: Question[]): QuestionTypes[] {
        const availableTypes = [];
        Object.values(QuestionTypes).forEach((type) => {
            if (questions.some((qs) => qs.question_type_value === type)) {
                availableTypes.push(type);
            }
        });

        return availableTypes;
    }

    public onDownload() {
        this.downloadHelper.download(this.occasion?.downloads);
    }

    ngOnDestroy() {
        this.destroy.next(true);
        this.destroy.unsubscribe();
    }
}
