<div fxFlex="0 0 100%" fxLayout="row wrap" class="access-select">
    <app-sticky-header
        class="sticky-header"
        [title]="data.title"
        [context]="data.contextTitle"
        [contextIcon]="data.contextIcon"
        (onSubmit)="submit()"
        (onCancel)="bottomSheetRef.dismiss()"
        [savingStatus]="saving"
    ></app-sticky-header>

    <mat-tab-group class="user-manage-cp-access mt-1" fxFlex="0 0 90%" ngClass.xs="xs">
        <mat-tab>
            <ng-template mat-tab-label>
                <span i18n="@@users">Users</span>
            </ng-template>
            <app-resource-access-control-users
                [permissionsForm]="permissionsForm"
                [resourcePermissions]="resourcePermissions"
                class="mt-1"
            ></app-resource-access-control-users>
        </mat-tab>

        <mat-tab>
            <ng-template mat-tab-label>
                <span i18n="@@groups">Groups</span>
            </ng-template>
            <app-resource-access-control-groups
                [permissionsForm]="permissionsForm"
                [resourcePermissions]="resourcePermissions"
                class="mt-1"
            ></app-resource-access-control-groups>
        </mat-tab>
    </mat-tab-group>
</div>
