import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CanComponentDeactivate } from '@klickdata/core/auth';
import { Group } from '@klickdata/core/group';
import { MobileService } from '@klickdata/core/mobile';
import { Filter, GlobalFilterProperty, SelectFilterOption } from '@klickdata/core/table';
import { Utils } from '@klickdata/core/util';
import { SelectModelBaseDirective } from '../select-model-base/select-model-base.component';
import { GroupsService } from './groups.service';

@Component({
    selector: 'app-admin-groups-select',
    templateUrl: './groups-select.component.html',
    styleUrls: ['./groups-select.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GroupsSelectComponent
    extends SelectModelBaseDirective<Group>
    implements OnInit, AfterViewInit, CanComponentDeactivate, OnDestroy
{
    @Input() accessForm: FormGroup;
    @Input() referenceFilter = 'referenceResource';
    @Input() referenceIdKey = 'id';
    @Input() customer_id: number;
    public columns = ['select', 'name', 'user_count', 'created_at', 'updated_at'];
    @Input() sync_all_key = 'sync_all_groups';
    @Input() attachKey = 'groups_attach';
    @Input() detachKey = 'groups_detach';
    @Input() assignId: number;
    @Input() filtersToBeApplied: Filter<string | number>[];
    public itemSelectedOptions: SelectFilterOption[];
    GlobalFilterProperty = GlobalFilterProperty;

    constructor(protected groupsService: GroupsService, protected mobile: MobileService) {
        super(groupsService, mobile);
        this.itemSelectedOptions = Utils.getItemSelectedOptions();
    }
    public ngAfterViewInit(): void {
        super.ngAfterViewInit();
        if (this.assignId) {
            this.filter.createOrUpdateFilter([new Filter('assign', [this.assignId])]);
        }
    }

    /**
     * @override
     * @param id
     */
    refresh(id?: any): void {
        const filters = [];
        if (this.referenceFilter) {
            filters.push({ property: this.referenceFilter, items: [id] });
        }
        if (this.customer_id) {
            filters.push({ property: 'customer', items: [this.customer_id] });
        }
        if (!!this.filtersToBeApplied?.length) {
            this.filtersToBeApplied
                .filter((fltr) => !!fltr.property && !!fltr.items?.length)
                .forEach((fltr) => filters.push(fltr));
        }
        this.filter.createOrUpdateFilter(filters);
    }

    detectIdKeyValueChange() {
        if (this.referenceFilter) {
            super.detectIdKeyValueChange();
        } else {
            this.refresh();
        }
    }
}
