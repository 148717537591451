import {
    NgxMatDatetimePickerModule,
    NgxMatNativeDateModule,
    NgxMatTimepickerModule,
} from '@angular-material-components/datetime-picker';
import { CommonModule } from '@angular/common';
import { ApplicationModule, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { MAT_DATE_FORMATS, MatNativeDateModule } from '@angular/material/core';
import { RouterModule } from '@angular/router';
import { APP_MOMENT_DATE_FORMATS } from '@klickdata/core/application/src/dates/app-date-format';
import { AuthModule } from '@klickdata/core/auth';
import { ConfigModule } from '@klickdata/core/config';
import { CustomerModule } from '@klickdata/core/customer';
import { FormModule } from '@klickdata/core/form';
import { HttpModule } from '@klickdata/core/http';
import { LocalizationModule } from '@klickdata/core/localization';
import { MediaModule } from '@klickdata/core/media';
import { MobileModule } from '@klickdata/core/mobile';
import { PostModule } from '@klickdata/core/post';
import { TableModule } from '@klickdata/core/table';
import { UserCoreModule } from '@klickdata/home/src/core/user-core.module';
import { KdLoaderModule } from '@klickdata/kd-loader';
import { MaterialModule } from '@klickdata/material';
import { EditorModule } from '@tinymce/tinymce-angular';
import { PipesModule } from 'apps/klickdata/src/app/shared/pipes/pipes.module';
import { DurationPickerModule } from 'ngx-duration-picker';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { AvatarComponent } from './application/avatar/avatar.component';
import { FooterComponent } from './application/footer/footer.component';
import { FullLayoutComponent } from './application/full-layout/full-layout.component';
import { SubMessageComponent } from './chat-dialog/chat-dialog.component';
import { CategoryChipSelectComponent } from './chip-selection/category-chip-select/category-chip-select.component';
import { GroupChipSelectComponent } from './chip-selection/group-chip-select/group-chip-select.component';
import { UserChipSelectComponent } from './chip-selection/user-chip-select/user-chip-select.component';
import { CoursePreviewComponent } from './course-preview/course-preview.component';
import { CoursePlanAccessComponent } from './create-course-plan-simple/course-plan-access/course-plan-access.component';
import { CoursePlanInformationsComponent } from './create-course-plan-simple/course-plan-informations/course-plan-informations.component';
import { AddCourseComponent } from './create-course-plan-simple/course-plan-items/add-course/add-course.component';
import { AddEcourseComponent } from './create-course-plan-simple/course-plan-items/add-ecourse/add-ecourse.component';
import { AddMaterialsComponent } from './create-course-plan-simple/course-plan-items/add-materials/add-materials.component';
import { AddSurveysComponent } from './create-course-plan-simple/course-plan-items/add-surveys/add-surveys.component';
import { AddTestsComponent } from './create-course-plan-simple/course-plan-items/add-tests/add-tests.component';
import { CoursePlanItemsComponent } from './create-course-plan-simple/course-plan-items/course-plan-items.component';
import { CoursePlanPublishComponent } from './create-course-plan-simple/course-plan-publish/course-plan-publish.component';
import { CoursePlanRemindersComponent } from './create-course-plan-simple/course-plan-reminders/course-plan-reminders.component';
import { CreateCoursePlanSimpleComponent } from './create-course-plan-simple/create-course-plan-simple.component';
import { CourseFromComponent } from './e-course/course-from/course-from.component';
import { CourseSettingsComponent } from './e-course/course-settings/course-settings.component';
import { EditResourceFieldComponent } from './edit-resource-field/edit-resource-field.component';
import { BrowseFolderComponent } from './folder/browse-folder/browse-folder.component';
import { FolderListComponent } from './folder/folder-list/folder-list.component';
import { GeneralResListingMobileComponent } from './general-res-listing-mobile/general-res-listing-mobile.component';
import { GeneralStatsResListingComponent } from './general-stats-res-listing/general-stats-res-listing.component';
import { GenericMobileTabsComponent } from './generic-mobile-tabs/generic-mobile-tabs.component';
import { GradeSystemControlComponent } from './grade-system/grade-system-control/grade-system-control.component';
import { AdminMenuComponent } from './header/admin-menu/admin-menu.component';
import { HeaderComponent } from './header/header.component';
import { LogoTypeComponent } from './header/logotype/logotype.component';
import { MobileHeaderComponent } from './header/mobile-header.component';
import { ModuleMenuItemComponent } from './header/module-menu-item/module-menu-item.component';
import { ModuleMenuComponent } from './header/module-menu/module-menu.component';
import { ModulesMenuComponent } from './header/modules-menu/modules-menu.component';
import { MainCatalogCoursesComponent } from './main-catalog/main-catalog-courses/main-catalog-courses.component';
import { MainCatalogEcoursesComponent } from './main-catalog/main-catalog-ecourses/main-catalog-ecourses.component';
import { MainCatalogMaterialsComponent } from './main-catalog/main-catalog-materials/main-catalog-materials.component';
import { MainCatalogResourceItemComponent } from './main-catalog/main-catalog-resource-item/main-catalog-resource-item.component';
import { MainCatalogSurveysComponent } from './main-catalog/main-catalog-surveys/main-catalog-surveys.component';
import { MainCatalogTestsComponent } from './main-catalog/main-catalog-tests/main-catalog-tests.component';
import { MainCatalogComponent } from './main-catalog/main-catalog.component';
import { MainResourceCatalogComponent } from './main-catalog/main-resource-catalog/main-resource-catalog.component';
import { MasterWellComponent } from './master-well/master-well.component';
import { AddMaterialLinkComponent } from './material-create/add-material-link/add-material-link.component';
import { AddMaterialTextComponent } from './material-create/add-material-text/add-material-text.component';
import { UploadMaterialFileComponent } from './material-create/upload-material-file/upload-material-file.component';
import { MenuSideActionsMsgComponent } from './menu-side-actions-msg/menu-side-actions-msg.component';
import { MenuSideAlertMsgComponent } from './menu-side-alert-msg/menu-side-alert-msg.component';
import { MenuSideMobileBottomSheetComponent } from './menu-side-mobile-bottom-sheet/menu-side-mobile-bottom-sheet.component';
import { MenuSideNotifierComponent } from './menu-side-notifier/menu-side-notifier.component';
import { MenuSideSendMailComponent } from './menu-side-send-mail/menu-side-send-mail.component';
import { MenuSideSendMessageComponent } from './menu-side-send-message/menu-side-send-message.component';
import { MenuGroupComponent } from './menu/menu-group/menu-group.component';
import { MenuItemComponent } from './menu/menu-item/menu-item.component';
import { MenuTitleDirective } from './menu/menu-title.directive';
import { MenuComponent } from './menu/menu.component';
import { CreateMessageComponent } from './messages-listing/create-message/create-message.component';
import { MessageCaseStatusActionComponent } from './messages-listing/create-message/message-case-status-action/message-case-status-action.component';
import { MessageCaseComponent } from './messages-listing/create-message/message-case/message-case.component';
import { MessageDialogueComponent } from './messages-listing/create-message/message-dialogue/message-dialogue.component';
import { MessageSystemComponent } from './messages-listing/create-message/message-system/message-system.component';
import { MessageTicketComponent } from './messages-listing/create-message/message-ticket/message-ticket.component';
import { MessagesListingComponent } from './messages-listing/messages-listing.component';
import { MobileListingItemComponent } from './mobile-listing-item/mobile-listing-item.component';
import { MobileMenuComponent } from './mobile-menu/mobile-menu.component';
import { NotesResourceListingDetailsComponent } from './notes-resource-listing/notes-resource-listing-details/notes-resource-listing-details.component';
import { PackageEcourseComponent } from './package-ecourse/package-ecourse.component';
import { PageHeaderComponent } from './page-header/page-header.component';
import { PreferredLangsSelectComponent } from './preferred-langs-select/preferred-langs-select.component';
import { PurchaseFaqComponent } from './purchase/purchase-faq/purchase-faq.component';
import { AppleByPurchaseComponent } from './purchase/purchase-methods/apple-by-purchase/apple-by-purchase.component';
import { PurchaseMethodsComponent } from './purchase/purchase-methods/purchase-methods.component';
import { SwishPurchaseComponent } from './purchase/purchase-methods/swish-purchase/swish-purchase.component';
import { PurchaseComponent } from './purchase/purchase.component';
import { ResourcePurchaseDialogComponent } from './purchase/resource-purchase-dialog/resource-purchase-dialog.component';
import { ResourcesAssignSheetComponent } from './resource-assignment/resource-assign-sheet/resource-assign-sheet.component';
import { ResourceAssignedListingComponent } from './resource-assignment/resource-assigned-listing/resource-assigned-listing.component';
import { UsersAssignStatusComponent } from './resource-assignment/resource-assigned-listing/users-assign-status/users-assign-status.component';
import { ResourceAvailabilitySheetComponent } from './resource-assignment/resource-availability-sheet/resource-availability-sheet.component';
import { ResourceCollectSheetComponent } from './resource-assignment/resource-collect-sheet/resource-collect-sheet.component';
import { ResourcePublicCustomersSheetComponent } from './resource-assignment/resource-public-customers-sheet/resource-public-customers-sheet.component';
import { ResourcePublishSelectSheetComponent } from './resource-assignment/resource-publish-select-sheet/resource-publish-select-sheet.component';
import { ResourceRecommendSheetComponent } from './resource-assignment/resource-recommend-sheet/resource-recommend-sheet.component';
import { CourseComponent } from './resource-builder/resouce-items/course/course.component';
import { MaterialComponent } from './resource-builder/resouce-items/material/material.component';
import { AlternativeFormComponent } from './resource-builder/resouce-items/question/alternatives/alternative-form.component';
import { AlternativesComponent } from './resource-builder/resouce-items/question/alternatives/alternatives.component';
import { CheckboxComponent } from './resource-builder/resouce-items/question/alternatives/checkbox/checkbox.component';
import { RadioComponent } from './resource-builder/resouce-items/question/alternatives/radio/radio.component';
import { QuestionFormComponent } from './resource-builder/resouce-items/question/question-form.component';
import { QuestionComponent } from './resource-builder/resouce-items/question/question.component';
import { ResourceItemFormComponent } from './resource-builder/resouce-items/resource-item-form.component';
import { ResourceItemsComponent } from './resource-builder/resouce-items/resource-items.component';
import { SurveyComponent } from './resource-builder/resouce-items/survey/survey.component';
import { TestComponent } from './resource-builder/resouce-items/test/test.component';
import { VideoComponent } from './resource-builder/resouce-items/video/video.component';
import { WokQuestionComponent } from './resource-builder/resouce-items/wok-question/wok-question.component';
import { ResourceBuilderFormComponent } from './resource-builder/resource-builder-form.component';
import { ResourceBuilderToolsViewComponent } from './resource-builder/resource-builder-tools/resource-builder-tools-view/resource-builder-tools-view.component';
import { ResourceBuilderToolsComponent } from './resource-builder/resource-builder-tools/resource-builder-tools.component';
import {
    ResourceBuilderComponent,
    ResourceBuilderEmptyDirective,
    ResourceBuilderTitleDirective,
} from './resource-builder/resource-builder.component';
// tslint:disable-next-line: max-line-length
import { MAT_COLOR_FORMATS, NgxMatColorPickerModule } from '@angular-material-components/color-picker';
import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';
import { GoogleMapsModule } from '@angular/google-maps';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { CUSTOM_MAT_COLOR_FORMATS } from '@klickdata/core/application/src/colors/app-custom-color-format';
import { CustomFieldsModule } from '@klickdata/core/custom-field';
import { ResizeObserverDirective } from '@klickdata/core/util';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { FileUploadModule } from 'ng2-file-upload';
import { TaskListingChildComponent } from './Task/task-listing-child/task-listing-child.component';
import { TaskListingGeneralComponent } from './Task/task-listing-general/task-listing-general.component';
import { TaskNotesListComponent } from './Task/task-notes-list/task-notes-list.component';
import { TaskNotesComponent } from './Task/task-notes/task-notes.component';
import { ActivityLogSxBoxComponent } from './activity-log-sx/activity-log-sx-box/activity-log-sx-box.component';
import { ActivityLogSxComponent } from './activity-log-sx/activity-log-sx.component';
import { AiModelSelectorComponent } from './ai-model-selector/ai-model-selector.component';
import { AiPrompterManagerComponent } from './ai-prompter-manager/ai-prompter-manager.component';
import { GuestOnboardingFooterComponent } from './application/guest-onboarding-footer/guest-onboarding-footer.component';
import { BlockLoaderComponent } from './block-loader/block-loader.component';
import { BreadcrumbRoutersComponent } from './breadcrumb-routers/breadcrumb-routers.component';
import { BrowseSectionListComponent } from './browse-section/browse-section-list/browse-section-list.component';
import { BrowseSectionComponent } from './browse-section/browse-section.component';
import { CalendarDisplayWithDatesComponent } from './calendar-display-with-dates/calendar-display-with-dates.component';
import { CategoriesAssignSheetComponent } from './categories-assign-sheet/categories-assign-sheet.component';
import { CheckboxWithCustomUserClickComponent } from './checkbox-with-custom-user-click/checkbox-with-custom-user-click.component';
import { ChipSelectComponent } from './chip-selection/chip-select/chip-select.component';
import { CustomerChipSelectComponent } from './chip-selection/customer-chip-select/customer-chip-select.component';
import { ChipsWithAddHandlerComponent } from './chips-with-add-handler/chips-with-add-handler.component';
import { CircleGaugeComponent } from './circle-gauge/circle-gauge.component';
import { CollectionBrowseBottomSheetComponent } from './collection-manage-bottom-sheet/collection-browse-bottom-sheet/collection-browse-bottom-sheet.component';
import { CollectionManageBottomSheetComponent } from './collection-manage-bottom-sheet/collection-manage-bottom-sheet.component';
import { CollectionSelectComponent } from './collection-manage-bottom-sheet/collection-select/collection-select.component';
import { CollectionItemBuilderComponent } from './collections/collection-item-builder/collection-item-builder.component';
import { CollectionItemChildrenListComponent } from './collections/collection-item-sheet-browse-children/collection-item-children-list/collection-item-children-list.component';
import { CollectionItemSheetBrowseChildrenComponent } from './collections/collection-item-sheet-browse-children/collection-item-sheet-browse-children.component';
import { CollectionItemSheetComponent } from './collections/collection-item-sheet/collection-item-sheet.component';
import { CollectionsListingSelectComponent } from './collections/collections-listing-select/collections-listing-select.component';
import { ColorsPickerInputFieldComponent } from './colors-picker-input-field/colors-picker-input-field.component';
import { CommunicationsComponent } from './communications/communications.component';
import { ConnectionListingGeneralComponent } from './contact/connection-listing-general/connection-listing-general.component';
import { CourseManagerCoreAddEventComponent } from './course-manager-core/course-manager-core-items/course-manager-core-add-event/course-manager-core-add-event.component';
import { CourseManagerEventCreateComponent } from './course-manager-core/course-manager-core-items/course-manager-core-add-event/course-manager-event-create/course-manager-event-create.component';
import { CourseManagerCoreAddMaterialComponent } from './course-manager-core/course-manager-core-items/course-manager-core-add-material/course-manager-core-add-material.component';
import { CourseManagerCoreMaterialManagerComponent } from './course-manager-core/course-manager-core-items/course-manager-core-add-material/course-manager-core-material-manager/course-manager-core-material-manager.component';
import { CourseManagerCoreAddSurveyComponent } from './course-manager-core/course-manager-core-items/course-manager-core-add-survey/course-manager-core-add-survey.component';
import { CourseManagerCreateSurveyComponent } from './course-manager-core/course-manager-core-items/course-manager-core-add-survey/course-manager-create-survey/course-manager-create-survey.component';
import { CourseManagerCoreAddTestComponent } from './course-manager-core/course-manager-core-items/course-manager-core-add-test/course-manager-core-add-test.component';
import { CourseManagerCoreCreateAiTestComponent } from './course-manager-core/course-manager-core-items/course-manager-core-add-test/course-manager-core-create-ai-test/course-manager-core-create-ai-test.component';
import { CourseManagerCreateTestComponent } from './course-manager-core/course-manager-core-items/course-manager-core-add-test/course-manager-create-test/course-manager-create-test.component';
import { CourseManagerCoreItemsComponent } from './course-manager-core/course-manager-core-items/course-manager-core-items.component';
import { CourseManagerCoreSecodaryControlsComponent } from './course-manager-core/course-manager-core-secodary-controls/course-manager-core-secodary-controls.component';
import { CourseManagerCoreComponent } from './course-manager-core/course-manager-core.component';
import { CoursePlayerResultsDiplomaComponent } from './course-player-results-diploma/course-player-results-diploma.component';
import { CoursePlayerResultsContentComponent } from './course-player-results/course-player-results-content/course-player-results-content.component';
import { CoursePlayerResultsQuestionsComponent } from './course-player-results/course-player-results-questions/course-player-results-questions.component';
import { CoursePlayerResultsResultComponent } from './course-player-results/course-player-results-result/course-player-results-result.component';
import { CoursePlayerResultsComponent } from './course-player-results/course-player-results.component';
import { CustomFieldsComponent } from './custom-fields/custom-fields.component';
import { DateTimeDisplayedCalendarPickerComponent } from './date-time-displayed-calendar-picker/date-time-displayed-calendar-picker.component';
import { DateTimePickerItemComponent } from './date-time-picker-item/date-time-picker-item.component';
import { EventManagerCoreItemsComponent } from './event-manager-core/event-manager-core-items/event-manager-core-items.component';
import { EventManagerCoreSecondaryControlsComponent } from './event-manager-core/event-manager-core-secondary-controls/event-manager-core-secondary-controls.component';
import { EventManagerCoreComponent } from './event-manager-core/event-manager-core.component';
import { ExpansionBlockHeaderComponent } from './expansion-block-header/expansion-block-header.component';
import { FilesListingGeneralComponent } from './files/files-listing-general/files-listing-general.component';
import { FilesNamesListingComponent } from './files/files-names-listing/files-names-listing.component';
import { FolderChipSelectComponent } from './folder/folder-chip-select/folder-chip-select.component';
import { AddFormativeEvaluationComponent } from './formative-evaluation/add-formative-evaluation/add-formative-evaluation.component';
import { FormativeEvaluationBottomSheetComponent } from './formative-evaluation/formative-evaluation-bottom-sheet/formative-evaluation-bottom-sheet.component';
import { FormativeEvaluationItemComponent } from './formative-evaluation/formative-evaluation-item/formative-evaluation-item.component';
import { FormativeEvaluationComponent } from './formative-evaluation/formative-evaluation.component';
import { GeneralTestOccasionsResultsComponent } from './general-test-occasions-results/general-test-occasions-results.component';
import { GeneralTestResultsComponent } from './general-test-results/general-test-results.component';
import { QuestionsWithAltsResultsComponent } from './general-test-results/questions-with-alts-results/questions-with-alts-results.component';
import { QuestionsWithNoAltsResultsComponent } from './general-test-results/questions-with-no-alts-results/questions-with-no-alts-results.component';
import { GeoLocationControlComponent } from './google-geolocation/geo-location-control/geo-location-control.component';
import { GeneralChatGPTComponent } from './header/general-chat-gpt/general-chat-gpt.component';
import { GeneralRecorderComponent } from './header/general-recorder/general-recorder.component';
import { RecorderAudioComponent } from './header/general-recorder/recorder-audio/recorder-audio.component';
import { RecorderBarComponent } from './header/general-recorder/recorder-bar/recorder-bar.component';
import { RecorderScreenWithVideoComponent } from './header/general-recorder/recorder-screen-with-video/recorder-screen-with-video.component';
import { RecorderScreenComponent } from './header/general-recorder/recorder-screen/recorder-screen.component';
import { RecorderVideoComponent } from './header/general-recorder/recorder-video/recorder-video.component';
import { RecorderViewComponent } from './header/general-recorder/recorder-view/recorder-view.component';
import { IframeDisplayLinkComponent } from './iframe-display-link/iframe-display-link.component';
import { ItemChangeOnTheFlyComponent } from './item-change-on-the-fly/item-change-on-the-fly.component';
import { ItemTranslatorComponent } from './item-translator/item-translator.component';
import { K3NotesListingComponent } from './k3-notes-listing/k3-notes-listing.component';
import { LanguageViewierComponent } from './language-viewier/language-viewier.component';
import { AddMaterialAiComponent } from './material-create/add-material-ai/add-material-ai.component';
import { MaterialItemsDisplayInfoComponent } from './material-items-display-info/material-items-display-info.component';
import { MaterialManagerTextItemHeaderComponent } from './materials-manager-core/material-manager-text-item-header/material-manager-text-item-header.component';
import { MaterialsManagerCoreComponent } from './materials-manager-core/materials-manager-core.component';
import { MaterialsManagerFileComponent } from './materials-manager-core/materials-manager-file/materials-manager-file.component';
import { MaterialsManagerLinkComponent } from './materials-manager-core/materials-manager-link/materials-manager-link.component';
import { MaterialsManagerTextComponent } from './materials-manager-core/materials-manager-text/materials-manager-text.component';
import { MediaListingGeneralComponent } from './medias/media-listing-general/media-listing-general.component';
import { MenuSideAddAddressComponent } from './menu-side-add-address/menu-side-add-address.component';
import { MenuSideAddCourseItemsComponent } from './menu-side-add-course-items/menu-side-add-course-items.component';
import { MenuSideAddCustomerComponent } from './menu-side-add-customer/menu-side-add-customer.component';
import { MenuSideAddEducatorComponent } from './menu-side-add-educator/menu-side-add-educator.component';
import { MenuSideAddHrNoteComponent } from './menu-side-add-hr-note/menu-side-add-hr-note.component';
import { MenuSideAddMediaFileComponent } from './menu-side-add-media-file/menu-side-add-media-file.component';
import { MenuSideAddMediaWithOptionsComponent } from './menu-side-add-media-with-options/menu-side-add-media-with-options.component';
import { MenuSideMediaLibraryComponent } from './menu-side-add-media-with-options/menu-side-media-library/menu-side-media-library.component';
import { MenuSideMediaUploadImgComponent } from './menu-side-add-media-with-options/menu-side-media-upload-img/menu-side-media-upload-img.component';
import { MenuSideAddNextContactComponent } from './menu-side-add-next-contact/menu-side-add-next-contact.component';
import { MenuSideAddNoteComponent } from './menu-side-add-note/menu-side-add-note.component';
import { MenuSideAddNotesCommentComponent } from './menu-side-add-notes-comment/menu-side-add-notes-comment.component';
import { MenuSideAddQuoteComponent } from './menu-side-add-quote/menu-side-add-quote.component';
import { MenuSideAddResourceQuestionsComponent } from './menu-side-add-resource-questions/menu-side-add-resource-questions.component';
import { MenuSideAddRoleComponent } from './menu-side-add-role/menu-side-add-role.component';
import { MenuSideAddTaskComponent } from './menu-side-add-task/menu-side-add-task.component';
import { MenuSideAddUserComponent } from './menu-side-add-user/menu-side-add-user.component';
import { MenuSideAiPrompterComponent } from './menu-side-ai-prompter/menu-side-ai-prompter.component';
import { MenuSideCancelCoursePlayComponent } from './menu-side-cancel-course-play/menu-side-cancel-course-play.component';
import { MenuSideCourseItemDoneAllComponent } from './menu-side-course-item-done-all/menu-side-course-item-done-all.component';
import { MenuSideCourseItemDoneComponent } from './menu-side-course-item-done/menu-side-course-item-done.component';
import { MenuSideCourseMaterialItemDoneComponent } from './menu-side-course-material-item-done/menu-side-course-material-item-done.component';
import { MenuSideCourseRegisterComponent } from './menu-side-course-register/menu-side-course-register.component';
import { MenuSideCreateGroupComponent } from './menu-side-create-group/menu-side-create-group.component';
import { MenuSideCreateLinkMaterialComponent } from './menu-side-create-link-material/menu-side-create-link-material.component';
import { MenuSideCreateResourceComponent } from './menu-side-create-resource/menu-side-create-resource.component';
import { MenuSideCustomFieldsListingComponent } from './menu-side-custom-fields-listing/menu-side-custom-fields-listing.component';
import { MenuSideDashboardColumnSettingsComponent } from './menu-side-dashboard-column-settings/menu-side-dashboard-column-settings.component';
import { MenuSideDateRangeComponent } from './menu-side-date-range/menu-side-date-range.component';
import { MenuSideDisplayMediaComponent } from './menu-side-display-media/menu-side-display-media.component';
import { MenuSideDownloadComponent } from './menu-side-download/menu-side-download.component';
import { MenuSideEmailMultipleUsersComponent } from './menu-side-email-multiple-users/menu-side-email-multiple-users.component';
import { MenuSideEventJoinComponent } from './menu-side-event-join/menu-side-event-join.component';
import { MenuSideEventRegComponent } from './menu-side-event-reg/menu-side-event-reg.component';
import { MenuSideFormBuilderComponent } from './menu-side-form-builder/menu-side-form-builder.component';
import { MenuSideGenerateAIComponent } from './menu-side-generate-ai/menu-side-generate-quote.component';
import { MenuSideLogUserResourceEventsComponent } from './menu-side-log-user-resource-events/menu-side-log-user-resource-events.component';
import { MenuSideLoginRegisterComponent } from './menu-side-login-register/menu-side-login-register.component';
import { MenuSideMailTextBlockComponent } from './menu-side-mail-text-block/menu-side-mail-text-block.component';
import { MenuSideManageAskAiPredefinedQsComponent } from './menu-side-manage-ask-ai-predefined-qs/menu-side-manage-ask-ai-predefined-qs.component';
import { MenuSideManageWidgetsComponent } from './menu-side-manage-widgets/menu-side-manage-widgets.component';
import { MenuSideQuestionExtraInfoComponent } from './menu-side-question-extra-info/menu-side-question-extra-info.component';
import { MenuSideQuestionReviewComponent } from './menu-side-question-review/menu-side-question-review.component';
import { MenuSideQuickAddCompetenceComponent } from './menu-side-quick-add-competence/menu-side-quick-add-competence.component';
import { MenuSideRelatedResourcesComponent } from './menu-side-related-resources/menu-side-related-resources.component';
import { MenuSideResEfficiencyComponent } from './menu-side-res-efficiency/menu-side-res-efficiency.component';
import { MenuSideResourceDoneAUActivityComponent } from './menu-side-resource-done-au-activity/menu-side-resource-done-au-activity';
import { MenuSideResourceEnrollmentApproveComponent } from './menu-side-resource-enrollment-approve/menu-side-resource-enrollment-approve.component';
import { MenuSideResourceEnrollmentRequestComponent } from './menu-side-resource-enrollment-request/menu-side-resource-enrollment-request.component';
import { MenuSideResourceManagersComponent } from './menu-side-resource-managers/menu-side-resource-managers.component';
import { MenuSideSelectPermissionLevelComponent } from './menu-side-select-permission-level/menu-side-select-permission-level.component';
import { MenuSideSendGroupEmailComponent } from './menu-side-send-group-email/menu-side-send-group-email.component';
import { MenuSideShowAllQuotesComponent } from './menu-side-show-all-quotes/menu-side-show-all-quotes.component';
import { MenuSideShowQuoteeQuotesComponent } from './menu-side-show-quotee-quotes/menu-side-show-quotee-quotes.component';
import { MenuSideSignoffFeedbackComponent } from './menu-side-signoff-feedback/menu-side-signoff-feedback.component';
import { MenuSideTextAreaFieldComponent } from './menu-side-text-area-field/menu-side-text-area-field.component';
import { MenuSideTextareaWithMultipleActionsComponent } from './menu-side-textarea-with-multiple-actions/menu-side-textarea-with-multiple-actions.component';
import { MenuSideToggleCompetenceComponent } from './menu-side-toggle-competence/menu-side-toggle-competence.component';
import { MenuSideUpdateCompComponent } from './menu-side-update-comp/menu-side-update-comp.component';
import { MenuSideUpdateRecurringComponent } from './menu-side-update-recurring/menu-side-update-recurring.component';
import { MenuSideUserActionsTemplateComponent } from './menu-side-user-actions-template/menu-side-user-actions-template.component';
import { MenuSideUsersStatusComponent } from './menu-side-users-status/menu-side-users-status.component';
import { MenuSideValidateSignoffComponent } from './menu-side-validate-signoff/menu-side-validate-signoff.component';
import { MenuSideValidationFeedbackComponent } from './menu-side-validation-feedback/menu-side-validation-feedback.component';
import { MenuSideVideoToQuizComponent } from './menu-side-video-to-quiz/menu-side-video-to-quiz.component';
import { MessageSubComponent } from './messages-listing/create-message/message-sub/message-sub.component';
import { InputsItemComponent } from './multiple-string-inputs/inputs-item/inputs-item.component';
import { MultipleStringInputsComponent } from './multiple-string-inputs/multiple-string-inputs.component';
import { NotesContextDisplayComponent } from './notes-context-display/notes-context-display.component';
import { NotesContextComponent } from './notes-context/notes-context.component';
import { NotesResourceListingOwnedComponent } from './notes-resource-listing/notes-resource-listing-owned/notes-resource-listing-owned.component';
import { NotesResourceListingSharedComponent } from './notes-resource-listing/notes-resource-listing-shared/notes-resource-listing-shared.component';
import { NotesResourceListingComponent } from './notes-resource-listing/notes-resource-listing.component';
import { PostsAssignmentComponent } from './posts-assignment/posts-assignment.component';
import { PurchaseManagerComponent } from './purchase/purchase-methods/purchase-manager/purchase-manager.component';
import { QuotesSliderComponent } from './quotes-slider/quotes-slider.component';
import { GeneralQuotesListingComponent } from './quotes/general-quotes-listing/general-quotes-listing.component';
import { QuotesManagerComponent } from './quotes/quotes-manager/quotes-manager.component';
import { QuotesTypeAudioComponent } from './quotes/quotes-manager/quotes-type-audio/quotes-type-audio.component';
import { QuotesTypeImgComponent } from './quotes/quotes-manager/quotes-type-img/quotes-type-img.component';
import { QuotesTypeTextComponent } from './quotes/quotes-manager/quotes-type-text/quotes-type-text.component';
import { QuotesTypeVideoComponent } from './quotes/quotes-manager/quotes-type-video/quotes-type-video.component';
import { GroupResourcesAccessControlComponent } from './resource-assignment/group-resources-access-control/group-resources-access-control.component';
import { ResourceAccessControlGroupsComponent } from './resource-assignment/resource-access-control-sheet/resource-access-control-groups/resource-access-control-groups.component';
import { ResourceAccessControlSheetComponent } from './resource-assignment/resource-access-control-sheet/resource-access-control-sheet.component';
import { ResourceAccessControlUsersComponent } from './resource-assignment/resource-access-control-sheet/resource-access-control-users/resource-access-control-users.component';
import { ResourcePermissionsHandlerComponent } from './resource-assignment/resource-access-control-sheet/resource-permissions-handler/resource-permissions-handler.component';
import { UserResourcesPermissionsHandlerComponent } from './resource-assignment/resource-access-control-sheet/user-resources-permissions-handler/user-resources-permissions-handler.component';
import { ResourceAccessSpecificPermissionComponent } from './resource-assignment/resource-access-specific-permission/resource-access-specific-permission.component';
import { ResourceCreationAssignmentSheetComponent } from './resource-assignment/resource-creation-assignment-sheet/resource-creation-assignment-sheet.component';
import { ResourceDetailsSheetComponent } from './resource-assignment/resource-details-sheet/resource-details-sheet.component';
import { ResourceInviteSheetComponent } from './resource-assignment/resource-invite-sheet/resource-invite-sheet.component';
import { ResourceManadatorySheetComponent } from './resource-assignment/resource-manadatory-sheet/resource-manadatory-sheet.component';
import { UserResourcesAccessControlComponent } from './resource-assignment/user-resources-access-control/user-resources-access-control.component';
import { UsersGroupsAccessControlSheetComponent } from './resource-assignment/users-groups-access-control-sheet/users-groups-access-control-sheet.component';
import { QuestionItemFormComponent } from './resource-builder/questions-items/question-item-form.component';
import { QuestionsItemsComponent } from './resource-builder/questions-items/questions-items.component';
import { ResourceBuilderEventComponent } from './resource-builder/resouce-items/event/resource-builder-event.component';
import { ResourceBuilderAiLoaderPanelComponent } from './resource-builder/resource-builder-ai-loader-panel/resource-builder-ai-loader-panel.component';
import { ResourceItemQuestionAlternativesComponent } from './resource-builder/resource-items-list/resource-item-question-alternatives/resource-item-question-alternatives.component';
import { ResourceItemQuestionComponent } from './resource-builder/resource-items-list/resource-item-question/resource-item-question.component';
import { ResourceItemComponent } from './resource-builder/resource-items-list/resource-item/resource-item.component';
import { ResourceItemsListComponent } from './resource-builder/resource-items-list/resource-items-list.component';
import { ResourceCourseDetailsComponent } from './resource-course-details/resource-course-details.component';
import { ResourceDetailsBottomSheetComponent } from './resource-details-bottom-sheet/resource-details-bottom-sheet.component';
import { ResourceDetailsBaseComponent } from './resource-details/resource-details-base/resource-details-base.component';
import { ResourceDetailsCourseComponent } from './resource-details/resource-details-course/resource-details-course.component';
import { ResourceDetailsEcourseComponent } from './resource-details/resource-details-ecourse/resource-details-ecourse.component';
import { ResourceDetailsEventComponent } from './resource-details/resource-details-event/resource-details-event.component';
import { ResourceDetailsInfoComponent } from './resource-details/resource-details-info/resource-details-info.component';
import { ResourceDetailsMaterialComponent } from './resource-details/resource-details-material/resource-details-material.component';
import { ResourceDetailsScormComponent } from './resource-details/resource-details-scorm/resource-details-scorm.component';
import { ResourceDetailsSurveyComponent } from './resource-details/resource-details-survey/resource-details-survey.component';
import { ResourceDetailsTestComponent } from './resource-details/resource-details-test/resource-details-test.component';
import { ResourceDetailsComponent } from './resource-details/resource-details.component';
import { ResourceEditDialogComponent } from './resource-edit-dialog/resource-edit-dialog.component';
import { ResourceEditThirdColumnComponent } from './resource-edit-third-column/resource-edit-third-column.component';
import { ResourceEducatorHandlerComponent } from './resource-educator-handler/resource-educator-handler.component';
import { ResourceHistoryListingComponent } from './resource-history-listing/resource-history-listing.component';
import { ResourceLibraryMultipleSelectComponent } from './resource-list/resource-library-multiple-select/resource-library-multiple-select.component';
import { ResourceLibraryComponent } from './resource-list/resource-library/resource-library.component';
import { ResourceListComponent } from './resource-list/resource-list.component';
import { ResourceListingSheetComponent } from './resource-listing-sheet/resource-listing-sheet.component';
import { GenericItemsListingComponent } from './resource-listing/generic-items-listing/generic-items-listing.component';
import { ResourceListingGeneralComponent } from './resource-listing/resource-listing-general/resource-listing-general.component';
import { ResourceListingLibraryComponent } from './resource-listing/resource-listing-library/resource-listing-library.component';
import { ResourceListingComponent } from './resource-listing/resource-listing.component';
import { UserListingGeneralComponent } from './resource-listing/user-listing-general/user-listing-general.component';
import { ResourceManagerAuthorHandlerComponent } from './resource-manager-author-handler/resource-manager-author-handler.component';
import { ResourceManagerControlsComponent } from './resource-manager/resource-manager-controls/resource-manager-controls.component';
import { ResourceManagementComponent } from './resource-manager/resource-manager.component';
import { ResourcePublisherHandlerComponent } from './resource-publisher-handler/resource-publisher-handler.component';
import { ResourceSearchListingComponent } from './resource-search-listing/resource-search-listing.component';
import { ResourceTableBaseComponent } from './resource-table-base/resource-table-base.component';
import { ResourceTitleWithIconComponent } from './resource-title-with-icon/resource-title-with-icon.component';
import { ResourcesCompetenceListSheetComponent } from './resources-competence-list-sheet/resources-competence-list-sheet.component';
import { ResourcesCompetenceListComponent } from './resources-competence-list/resources-competence-list.component';
import { ResourcesGridBaseComponent } from './resources-grid-base/resources-grid-base.component';
import { ResourcesSubGridBaseComponent } from './resources-grid-base/resources-sub-grid-base/resources-sub-grid-base.component';
import { ResourcesGridItemComponent } from './resources-grid-item/resources-grid-item.component';
import { ResourcesListingUserOccasionsComponent } from './resources-listing-user-occasions/resources-listing-user-occasions.component';
import { ScormCreateComponent } from './scorm-create/scorm-create.component';
import { ScormManagerCoreComponent } from './scorm-manager-core/scorm-manager-core.component';
import { ScormUploadComponent } from './scorm-upload/scorm-upload.component';
import { MainResultsComponent } from './search/main-results/main-results.component';
import { MainSearchComponent } from './search/main-search/main-search.component';
import { SearchComponent } from './search/search.component';
import { SectionItemParentComponent } from './section-item-parent/section-item-parent.component';
import { SectionItemComponent } from './section-item/section-item.component';
import { SectionItemsListViewComponent } from './section-items-list-view/section-items-list-view.component';
import { SectionsViewerSheetComponent } from './sections-viewer-sheet/sections-viewer-sheet.component';
import { CasesSelectComponent } from './selection-handler/cases-select/cases-select.component';
import { CategoriesSelectComponent } from './selection-handler/categories-select/categories-select.component';
import { CollectionsChildrenSelectComponent } from './selection-handler/collections-children-select/collections-children-select.component';
import { CustomerSelectComponent } from './selection-handler/customer-select/customer-select.component';
import { FolderSelectComponent } from './selection-handler/folder-select/folder-select.component';
import { GroupsSelectWithStationsComponent } from './selection-handler/groups-select-with-stations/groups-select-with-stations.component';
import { GroupsSelectComponent } from './selection-handler/groups-select/groups-select.component';
import { ResourceSelectComponent } from './selection-handler/resources-assign/resource-select/resource-select.component';
import { ResourcesAssignComponent } from './selection-handler/resources-assign/resources-assign.component';
import { SectionSelectSheetComponent } from './selection-handler/section-select/section-select-sheet/section-select-sheet.component';
import { SectionSelectComponent } from './selection-handler/section-select/section-select.component';
import { TagsSelectComponent } from './selection-handler/tags-select/tags-select.component';
import { TeamGroupsSelectComponent } from './selection-handler/team-groups-select/team-groups-select.component';
import { TeamGroupsUsersSelectSheetComponent } from './selection-handler/team-groups-select/team-groups-users-select-sheet/team-groups-users-select-sheet.component';
import { TeamsSelectComponent } from './selection-handler/teams-select/teams-select.component';
import { UserSelectWithCheckEditComponent } from './selection-handler/users-select/user-select-with-check-edit/user-select-with-check-edit.component';
import { UsersSelectHasOccasionsComponent } from './selection-handler/users-select/users-select-has-occasions/users-select-has-occasions.component';
import { UsersSelectReminderComponent } from './selection-handler/users-select/users-select-reminder/users-select-reminder.component';
import { UsersSelectToolsComponent } from './selection-handler/users-select/users-select-tools/users-select-tools.component';
import { UsersSelectComponent } from './selection-handler/users-select/users-select.component';
import { SettingsWellComponent } from './settings-well/settings-well.component';
import { ShareModule } from './share/share.module';
import { SharedActionLogOverallComponent } from './shared-action-log/shared-action-log-overall/shared-action-log-overall.component';
import { SharedActionLogResourcesUsersComponent } from './shared-action-log/shared-action-log-resources/shared-action-log-resources-users/shared-action-log-resources-users.component';
import { SharedActionLogResourcesComponent } from './shared-action-log/shared-action-log-resources/shared-action-log-resources.component';
import { SharedActionLogUsersComponent } from './shared-action-log/shared-action-log-users/shared-action-log-users.component';
import { SharedLogUsersTabsCasesComponent } from './shared-action-log/shared-action-log-users/shared-log-users-tabs-cases/shared-log-users-tabs-cases.component';
import { SharedLogUsersTabsGroupsComponent } from './shared-action-log/shared-action-log-users/shared-log-users-tabs-groups/shared-log-users-tabs-groups.component';
import { SharedLogUsersTabsResourcesComponent } from './shared-action-log/shared-action-log-users/shared-log-users-tabs-resources/shared-log-users-tabs-resources.component';
import { SharedLogUsersTabsComponent } from './shared-action-log/shared-action-log-users/shared-log-users-tabs/shared-log-users-tabs.component';
import { SharedActionLogComponent } from './shared-action-log/shared-action-log.component';
import { SharedHrNotesCommentsComponent } from './shared-hr-notes/shared-hr-notes-comments/shared-hr-notes-comments.component';
import { SharedHrNotesComponent } from './shared-hr-notes/shared-hr-notes.component';
import { ShipsWithAddingHandlerComponent } from './ships-with-adding-handler/ships-with-adding-handler.component';
import { SidebarLayoutComponent } from './sidebar/sidebar-layout/sidebar-layout.component';
import { SidebarMenuItemSubmenuComponent } from './sidebar/sidebar-menu/sidebar-menu-item-submenu/sidebar-menu-item-submenu.component';
import { SidebarMenuItemComponent } from './sidebar/sidebar-menu/sidebar-menu-item.component';
import { SidebarMenuComponent } from './sidebar/sidebar-menu/sidebar-menu.component';
import { SignatureChangeOnTheFlyComponent } from './signature-change-on-the-fly/signature-change-on-the-fly.component';
import { StickyHeaderComponent } from './sticky-header/sticky-header.component';
import { CreateSurveyComponent } from './survey-create/create-survey.component';
import { SurveyManagerCoreComponent } from './survey-manager-core/survey-manager-core.component';
import { TableFilterExpansionPanelComponent } from './table-filter-expansion-panel/table-filter-expansion-panel.component';
import { TableTagsOnTheFlyComponent } from './table-tags-on-the-fly/table-tags-on-the-fly.component';
import { TagChipSelectComponent } from './tag-chip-select/tag-chip-select.component';
import { TagItemChipSelectComponent } from './tag-item-chip-select/tag-item-chip-select.component';
import { TagRelatedScopesListingComponent } from './tag-related-scopes-listing/tag-related-scopes-listing.component';
import { TagsAssignSheetComponent } from './tags-assign-sheet/tags-assign-sheet.component';
import { TaskTagsHandlerComponent } from './task-tags-handler/task-tags-handler.component';
import { MultiRowsTable } from './templates/multi-row-table/multi-row-table';
import { CreateTestComponent } from './test-create/create-test.component';
import { TestManagerCoreComponent } from './test-manager-core/test-manager-core.component';
import { TestsResultsAnswersComponent } from './tests-results/tests-results-answers/tests-results-answers.component';
import { TestsResultsHeaderComponent } from './tests-results/tests-results-header/tests-results-header.component';
import { TestsResultsOverallComponent } from './tests-results/tests-results-overall/tests-results-overall.component';
import { TestsResultsQuestionsComponent } from './tests-results/tests-results-questions/tests-results-questions.component';
import { TestsResultsComponent } from './tests-results/tests-results.component';
import { EditorMergeFieldService } from './text-editor/editor-merge-field.service';
import { EditorTemplateService } from './text-editor/editor-template.service';
import { EditorComponent } from './text-editor/editor/editor.component';
import { EditComponent } from './text-editor/templates/edit/edit.component';
import { TemplatesComponent } from './text-editor/templates/templates.component';
import { TextEditorComponent } from './text-editor/text-editor.component';
import { TitleTranslationComponent } from './title-translation/title-translation.component';
import { UserPerosonalSettingsAndLanguageComponent } from './user-perosonal-settings-and-language/user-perosonal-settings-and-language.component';
import { UserPerosonalSettingsFavCategoriesComponent } from './user-perosonal-settings-and-language/user-perosonal-settings-fav-categories/user-perosonal-settings-fav-categories.component';
import { UserPerosonalSettingsPersonalInterestComponent } from './user-perosonal-settings-and-language/user-perosonal-settings-personal-interest/user-perosonal-settings-personal-interest.component';
import { UserSettingInputComponent } from './user-setting-input/user-setting-input.component';
import { UsersOptionsSelectionComponent } from './users-options-selection/users-options-selection.component';
import { VersionAlertComponent } from './version-alert/version-alert.component';
import { VideoLinkControlComponent } from './video-link-control/video-link-control.component';
import { VtqSlidersComponent } from './vtq-sliders/vtq-sliders.component';
import { StationsSelectInGroupComponent } from './selection-handler/groups-select-with-stations/stations-select-in-group/stations-select-in-group.component';

@NgModule({
    imports: [
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        ApplicationModule,
        CustomerModule,
        MobileModule,
        PostModule,
        LocalizationModule,
        CommonModule,
        UserCoreModule,
        FlexLayoutModule,
        MaterialModule,
        AuthModule,
        HttpModule,
        ConfigModule,
        TableModule,
        MediaModule,
        FormModule,
        EditorModule,
        PipesModule,
        DurationPickerModule,
        InfiniteScrollModule,
        ShareModule,
        KdLoaderModule,
        NgxMatDatetimePickerModule,
        NgxMatNativeDateModule,
        NgxMatTimepickerModule,
        // BrowserAnimationsModule,
        CarouselModule,
        MatDatepickerModule,
        MatNativeDateModule,
        GoogleMapsModule,
        FileUploadModule,
        NgxQRCodeModule,
        NgxMatColorPickerModule,
        CustomFieldsModule,
        MatPasswordStrengthModule,
    ],
    declarations: [
        AdminMenuComponent,
        LogoTypeComponent,
        ModuleMenuComponent,
        ModuleMenuItemComponent,
        ModulesMenuComponent,
        HeaderComponent,
        MobileHeaderComponent,
        SearchComponent,
        FullLayoutComponent,
        FooterComponent,
        MenuComponent,
        MenuTitleDirective,
        MenuGroupComponent,
        MenuItemComponent,
        MobileMenuComponent,
        CoursePreviewComponent,
        SidebarLayoutComponent,
        SidebarMenuComponent,
        SidebarMenuItemComponent,
        AvatarComponent,
        CategoryChipSelectComponent,
        CreateCoursePlanSimpleComponent,
        CoursePlanAccessComponent,
        CoursePlanInformationsComponent,
        CoursePlanItemsComponent,
        CoursePlanPublishComponent,
        CoursePlanRemindersComponent,
        AddTestsComponent,
        AddSurveysComponent,
        AddMaterialsComponent,

        AddMaterialLinkComponent,
        UploadMaterialFileComponent,
        AddMaterialTextComponent,
        CreateTestComponent,
        CreateSurveyComponent,
        GradeSystemControlComponent,
        VersionAlertComponent,

        EditComponent,
        TemplatesComponent,
        TextEditorComponent,
        EditorComponent,

        UserChipSelectComponent,
        GroupChipSelectComponent,
        UsersSelectComponent,
        GroupsSelectComponent,

        ResourcesAssignComponent,
        ResourceSelectComponent,

        CustomerSelectComponent,

        /** Resource builder */
        ResourceBuilderComponent,
        ResourceBuilderFormComponent,
        ResourceBuilderToolsComponent,
        ResourceItemsComponent,
        ResourceItemFormComponent,
        QuestionItemFormComponent,
        QuestionComponent,
        VideoComponent,
        QuestionFormComponent,
        AlternativesComponent,
        AlternativeFormComponent,
        CheckboxComponent,
        RadioComponent,
        ResourceItemsListComponent,
        ResourceItemQuestionComponent,
        ResourceItemQuestionAlternativesComponent,
        ResourceBuilderTitleDirective,
        ResourceBuilderEmptyDirective,
        TestComponent,
        CourseComponent,
        SurveyComponent,
        ResourceBuilderToolsViewComponent,
        MaterialComponent,
        ResourceItemComponent,
        WokQuestionComponent,

        // Resource listing
        ResourceListComponent,
        ResourceLibraryComponent,
        TagChipSelectComponent,
        FolderChipSelectComponent,
        ResourceDetailsComponent,
        TitleTranslationComponent,
        EditResourceFieldComponent,
        SectionSelectComponent,
        MasterWellComponent,
        PageHeaderComponent,
        ResourceAssignedListingComponent,
        ResourceCollectSheetComponent,
        StickyHeaderComponent,
        MainResultsComponent,
        SectionItemComponent,
        ResourceSearchListingComponent,

        //
        FolderSelectComponent,
        FolderListComponent,
        BrowseFolderComponent,
        ResourceCourseDetailsComponent,
        SettingsWellComponent,
        ResourceListingComponent,
        ResourceListingLibraryComponent,
        ResourceListingGeneralComponent,
        MainSearchComponent,

        CourseFromComponent,
        CourseSettingsComponent,
        ResourceEditDialogComponent,
        SectionItemParentComponent,
        TestsResultsComponent,
        TestsResultsHeaderComponent,
        TestsResultsOverallComponent,
        TestsResultsAnswersComponent,
        TestsResultsQuestionsComponent,
        UserSettingInputComponent,
        SectionItemsListViewComponent,
        GenericItemsListingComponent,
        UserListingGeneralComponent,
        GeneralStatsResListingComponent,
        ResourceRecommendSheetComponent,
        GeneralResListingMobileComponent,
        ResourcesAssignSheetComponent,
        MobileListingItemComponent,
        AddCourseComponent,
        AddEcourseComponent,
        PackageEcourseComponent,
        UsersAssignStatusComponent,
        GenericMobileTabsComponent,
        PurchaseComponent,
        SectionSelectSheetComponent,
        ResourcePurchaseDialogComponent,
        PurchaseFaqComponent,
        PurchaseMethodsComponent,
        SwishPurchaseComponent,
        AppleByPurchaseComponent,
        PreferredLangsSelectComponent,
        MainCatalogComponent,
        MainCatalogCoursesComponent,
        MainCatalogTestsComponent,
        MainCatalogSurveysComponent,
        MainCatalogMaterialsComponent,
        MainCatalogEcoursesComponent,
        MainCatalogResourceItemComponent,
        MainResourceCatalogComponent,
        ResourceListingSheetComponent,
        ScormCreateComponent,
        ScormUploadComponent,
        MessagesListingComponent,
        CreateMessageComponent,
        MessageDialogueComponent,
        MessageCaseComponent,
        MessageSystemComponent,
        SubMessageComponent,
        MessageCaseStatusActionComponent,
        MessageTicketComponent,
        MenuSideAlertMsgComponent,
        MenuSideActionsMsgComponent,
        MenuSideMobileBottomSheetComponent,
        MenuSideNotifierComponent,
        ResourcePublishSelectSheetComponent,
        ResourceAvailabilitySheetComponent,
        ResourcePublicCustomersSheetComponent,
        ResourceEditThirdColumnComponent,
        UsersOptionsSelectionComponent,
        MenuSideSendMailComponent,
        MenuSideSendMessageComponent,
        GeneralRecorderComponent,
        RecorderBarComponent,
        RecorderViewComponent,
        RecorderScreenComponent,
        RecorderAudioComponent,
        RecorderVideoComponent,
        RecorderScreenWithVideoComponent,
        MenuSideAddRoleComponent,
        ResourceCreationAssignmentSheetComponent,
        MenuSideSelectPermissionLevelComponent,
        ResourceInviteSheetComponent,
        CategoriesAssignSheetComponent,
        TagsSelectComponent,
        TagsAssignSheetComponent,
        CategoriesSelectComponent,
        MenuSideAddNoteComponent,
        MenuSideUsersStatusComponent,
        MenuSideResEfficiencyComponent,
        ResourceDetailsSheetComponent,
        ResourceManagementComponent,
        ResourceManagerControlsComponent,
        SharedActionLogComponent,
        SharedActionLogOverallComponent,
        SharedActionLogResourcesComponent,
        SharedActionLogUsersComponent,
        SharedLogUsersTabsComponent,
        SharedLogUsersTabsCasesComponent,
        SharedLogUsersTabsGroupsComponent,
        SharedLogUsersTabsResourcesComponent,
        SharedActionLogResourcesUsersComponent,
        MenuSideLogUserResourceEventsComponent,
        BlockLoaderComponent,
        CourseManagerCoreComponent,
        CourseManagerCoreSecodaryControlsComponent,
        CourseManagerCoreItemsComponent,
        CourseManagerCoreAddMaterialComponent,
        CourseManagerCoreAddSurveyComponent,
        CourseManagerCoreAddTestComponent,
        TestManagerCoreComponent,
        SurveyManagerCoreComponent,
        SectionsViewerSheetComponent,
        CasesSelectComponent,
        MenuSideAddNotesCommentComponent,
        MenuSideTextAreaFieldComponent,
        MenuSideDisplayMediaComponent,
        MultipleStringInputsComponent,
        ShipsWithAddingHandlerComponent,
        NotesResourceListingComponent,
        NotesResourceListingDetailsComponent,
        NotesResourceListingSharedComponent,
        NotesResourceListingOwnedComponent,
        ActivityLogSxComponent,
        ActivityLogSxBoxComponent,
        InputsItemComponent,
        BrowseSectionComponent,
        BrowseSectionListComponent,
        CollectionManageBottomSheetComponent,
        CollectionSelectComponent,
        CollectionBrowseBottomSheetComponent,
        CollectionsListingSelectComponent,
        CollectionItemSheetComponent,
        CollectionItemBuilderComponent,
        CollectionItemSheetBrowseChildrenComponent,
        CollectionsChildrenSelectComponent,
        CollectionItemChildrenListComponent,
        SidebarMenuItemSubmenuComponent,
        MenuSideManageWidgetsComponent,
        MenuSideQuestionExtraInfoComponent,
        MenuSideAddResourceQuestionsComponent,
        MenuSideDownloadComponent,
        MenuSideCourseRegisterComponent,
        MenuSideAddEducatorComponent,
        ResourceEducatorHandlerComponent,
        EventManagerCoreComponent,
        EventManagerCoreItemsComponent,
        EventManagerCoreSecondaryControlsComponent,
        MenuSideResourceManagersComponent,
        DateTimeDisplayedCalendarPickerComponent,
        GeoLocationControlComponent,
        ResourcePublisherHandlerComponent,
        GeneralChatGPTComponent,
        MenuSideAiPrompterComponent,
        AiPrompterManagerComponent,
        MenuSideAddMediaWithOptionsComponent,
        ItemTranslatorComponent,
        MenuSideCreateLinkMaterialComponent,
        MenuSideAddUserComponent,
        TableFilterExpansionPanelComponent,
        MenuSideAddTaskComponent,
        MenuSideMediaUploadImgComponent,
        MenuSideMailTextBlockComponent,
        ChipSelectComponent,
        CalendarDisplayWithDatesComponent,
        AddMaterialAiComponent,
        GeneralQuotesListingComponent,
        QuotesManagerComponent,
        PostsAssignmentComponent,
        VtqSlidersComponent,
        MenuSideVideoToQuizComponent,
        MenuSideAddQuoteComponent,
        QuotesTypeAudioComponent,
        QuotesTypeVideoComponent,
        QuotesTypeImgComponent,
        QuotesTypeTextComponent,
        MenuSideGenerateAIComponent,
        MenuSideShowAllQuotesComponent,
        ResourceDetailsCourseComponent,
        ResourceDetailsMaterialComponent,
        ResourceDetailsEcourseComponent,
        ResourceDetailsTestComponent,
        ResourceDetailsSurveyComponent,
        ResourceDetailsEventComponent,
        ResourceDetailsInfoComponent,
        MenuSideEventRegComponent,
        CircleGaugeComponent,
        VideoLinkControlComponent,
        MenuSideShowQuoteeQuotesComponent,
        QuotesSliderComponent,
        TaskListingGeneralComponent,
        TaskListingChildComponent,
        TaskNotesComponent,
        MenuSideAddCustomerComponent,
        ConnectionListingGeneralComponent,
        MultiRowsTable,
        SharedHrNotesComponent,
        SharedHrNotesCommentsComponent,
        CommunicationsComponent,
        TaskTagsHandlerComponent,
        MenuSideAddNextContactComponent,
        TaskNotesListComponent,
        DateTimePickerItemComponent,
        MenuSideAddHrNoteComponent,
        ItemChangeOnTheFlyComponent,
        SignatureChangeOnTheFlyComponent,
        MenuSideAddAddressComponent,
        TableTagsOnTheFlyComponent,
        NotesContextComponent,
        CustomerChipSelectComponent,
        NotesContextDisplayComponent,
        UserPerosonalSettingsAndLanguageComponent,
        UserPerosonalSettingsFavCategoriesComponent,
        UserPerosonalSettingsPersonalInterestComponent,
        ChipsWithAddHandlerComponent,
        MenuSideCreateGroupComponent,
        MenuSideAddMediaFileComponent,
        MediaListingGeneralComponent,
        FilesListingGeneralComponent,
        ResourceDetailsScormComponent,
        ResourceLibraryMultipleSelectComponent,
        MenuSideCreateResourceComponent,
        MenuSideMediaLibraryComponent,
        FilesNamesListingComponent,
        ColorsPickerInputFieldComponent,
        QuestionsItemsComponent,
        PurchaseManagerComponent,
        MenuSideFormBuilderComponent,
        MenuSideCustomFieldsListingComponent,
        CustomFieldsComponent,
        ResourceManadatorySheetComponent,
        UsersSelectToolsComponent,
        UsersSelectHasOccasionsComponent,
        UsersSelectReminderComponent,
        ResourcesCompetenceListComponent,
        MenuSideQuestionReviewComponent,
        FormativeEvaluationComponent,
        AddFormativeEvaluationComponent,
        MaterialsManagerCoreComponent,
        MaterialManagerTextItemHeaderComponent,
        MaterialsManagerTextComponent,
        MaterialsManagerLinkComponent,
        MaterialsManagerFileComponent,
        UserSelectWithCheckEditComponent,
        CourseManagerCoreMaterialManagerComponent,
        ResourceTitleWithIconComponent,
        MenuSideRelatedResourcesComponent,
        MenuSideSignoffFeedbackComponent,
        ResourcesListingUserOccasionsComponent,
        GeneralTestResultsComponent,
        QuestionsWithAltsResultsComponent,
        QuestionsWithNoAltsResultsComponent,
        MenuSideCourseItemDoneComponent,
        CoursePlayerResultsComponent,
        CoursePlayerResultsContentComponent,
        CoursePlayerResultsQuestionsComponent,
        CoursePlayerResultsResultComponent,
        CoursePlayerResultsDiplomaComponent,
        AiModelSelectorComponent,
        MenuSideDateRangeComponent,
        MaterialItemsDisplayInfoComponent,
        FormativeEvaluationItemComponent,
        FormativeEvaluationBottomSheetComponent,
        MenuSideUserActionsTemplateComponent,
        MenuSideCancelCoursePlayComponent,
        MenuSideTextareaWithMultipleActionsComponent,
        MenuSideResourceEnrollmentApproveComponent,
        MenuSideResourceEnrollmentRequestComponent,
        ResourceAccessControlSheetComponent,
        ResourceAccessControlUsersComponent,
        ResourceAccessControlGroupsComponent,
        ResourcePermissionsHandlerComponent,
        ResourceDetailsBottomSheetComponent,
        MenuSideCourseMaterialItemDoneComponent,
        MenuSideCourseItemDoneAllComponent,
        MenuSideResourceDoneAUActivityComponent,
        IframeDisplayLinkComponent,
        CheckboxWithCustomUserClickComponent,
        ResourceDetailsBaseComponent,
        ResourceTableBaseComponent,
        ResizeObserverDirective,
        ResourcesGridBaseComponent,
        ResourcesGridItemComponent,
        MenuSideDashboardColumnSettingsComponent,
        ExpansionBlockHeaderComponent,
        MessageSubComponent,
        CourseManagerCoreAddEventComponent,
        CourseManagerEventCreateComponent,
        ResourceBuilderEventComponent,
        MenuSideEventJoinComponent,
        ResourcesCompetenceListSheetComponent,
        MenuSideSendGroupEmailComponent,
        TeamGroupsSelectComponent,
        TeamGroupsUsersSelectSheetComponent,
        MenuSideUpdateRecurringComponent,
        MenuSideUpdateCompComponent,
        ResourceAccessSpecificPermissionComponent,
        TeamsSelectComponent,
        MenuSideToggleCompetenceComponent,
        ResourceManagerAuthorHandlerComponent,
        UserResourcesAccessControlComponent,
        UserResourcesPermissionsHandlerComponent,
        GroupResourcesAccessControlComponent,
        UsersGroupsAccessControlSheetComponent,
        ResourcesSubGridBaseComponent,
        MenuSideManageAskAiPredefinedQsComponent,
        ResourceHistoryListingComponent,
        MenuSideQuickAddCompetenceComponent,
        TagItemChipSelectComponent,
        TagRelatedScopesListingComponent,
        BreadcrumbRoutersComponent,
        MenuSideLoginRegisterComponent,

        GuestOnboardingFooterComponent,
        LanguageViewierComponent,
        MenuSideEmailMultipleUsersComponent,
        K3NotesListingComponent,
        MenuSideAddCourseItemsComponent,
        GeneralTestOccasionsResultsComponent,
        CourseManagerCreateTestComponent,
        CourseManagerCreateSurveyComponent,
        MenuSideValidateSignoffComponent,
        MenuSideValidationFeedbackComponent,
        CourseManagerCoreCreateAiTestComponent,
        ResourceBuilderAiLoaderPanelComponent,
        ScormManagerCoreComponent,

        GroupsSelectWithStationsComponent,
        StationsSelectInGroupComponent,
    ],

    exports: [
        AdminMenuComponent,
        LogoTypeComponent,
        ModuleMenuComponent,
        ModuleMenuItemComponent,
        ModulesMenuComponent,
        HeaderComponent,
        MobileHeaderComponent,
        SearchComponent,
        FullLayoutComponent,
        FooterComponent,
        MenuComponent,
        MenuGroupComponent,
        MenuItemComponent,
        MobileMenuComponent,
        CoursePreviewComponent,
        SidebarLayoutComponent,
        SidebarMenuComponent,
        SidebarMenuItemComponent,
        AvatarComponent,
        CategoryChipSelectComponent,
        CreateCoursePlanSimpleComponent,
        GradeSystemControlComponent,
        TextEditorComponent,
        EditorComponent,

        UserChipSelectComponent,
        GroupChipSelectComponent,
        UsersSelectComponent,
        GroupsSelectComponent,
        ResourcesAssignComponent,
        ResourceSelectComponent,
        SectionSelectComponent,
        CustomerSelectComponent,

        AddMaterialLinkComponent,
        UploadMaterialFileComponent,
        AddMaterialTextComponent,
        CreateTestComponent,
        CreateSurveyComponent,

        // Resource listing..
        ResourceListComponent,
        ResourceLibraryComponent,
        ResourceLibraryMultipleSelectComponent,

        /** Resource builder */
        ResourceBuilderComponent,
        ResourceItemsListComponent,
        ResourceItemQuestionComponent,
        ResourceItemQuestionAlternativesComponent,
        ResourceBuilderTitleDirective,
        ResourceBuilderEmptyDirective,
        LocalizationModule,
        TagChipSelectComponent,
        FolderChipSelectComponent,
        ResourceDetailsComponent,
        TitleTranslationComponent,
        EditResourceFieldComponent,
        MasterWellComponent,
        PageHeaderComponent,
        ResourceAssignedListingComponent,
        StickyHeaderComponent,
        MainResultsComponent,
        SectionItemComponent,
        FolderSelectComponent,
        FolderListComponent,
        ResourceCourseDetailsComponent,

        CoursePlanAccessComponent,
        CoursePlanRemindersComponent,
        CoursePlanPublishComponent,
        SettingsWellComponent,
        ResourceListingGeneralComponent,
        ResourceListingLibraryComponent,

        CourseFromComponent,
        CourseSettingsComponent,
        ResourceEditDialogComponent,
        SectionItemParentComponent,
        TestsResultsComponent,
        TestsResultsHeaderComponent,
        TestsResultsOverallComponent,
        TestsResultsAnswersComponent,
        TestsResultsQuestionsComponent,
        SectionItemsListViewComponent,
        UserSettingInputComponent,
        GenericItemsListingComponent,
        UserSettingInputComponent,
        UserListingGeneralComponent,
        GeneralStatsResListingComponent,
        ResourcesAssignSheetComponent,
        MobileListingItemComponent,
        GenericMobileTabsComponent,
        SectionSelectSheetComponent,
        ResourcePurchaseDialogComponent,
        PreferredLangsSelectComponent,
        MainCatalogComponent,
        MainCatalogCoursesComponent,
        MainCatalogTestsComponent,
        MainCatalogSurveysComponent,
        MainCatalogMaterialsComponent,
        MainCatalogEcoursesComponent,
        MainCatalogResourceItemComponent,
        MainResourceCatalogComponent,
        ResourceSearchListingComponent,
        ResourceListingSheetComponent,
        ScormCreateComponent,
        ScormUploadComponent,
        MessagesListingComponent,
        CreateMessageComponent,
        SubMessageComponent,
        MenuSideAlertMsgComponent,
        MenuSideActionsMsgComponent,
        MenuSideMobileBottomSheetComponent,
        MenuSideNotifierComponent,
        ResourcePublicCustomersSheetComponent,
        ResourceEditThirdColumnComponent,
        UsersOptionsSelectionComponent,
        MenuSideSendMailComponent,
        MenuSideSendMessageComponent,
        ResourceInviteSheetComponent,
        CategoriesAssignSheetComponent,
        CategoriesSelectComponent,
        TagsSelectComponent,
        TagsAssignSheetComponent,
        MenuSideAddNoteComponent,
        MenuSideUsersStatusComponent,
        MenuSideResEfficiencyComponent,
        ResourceDetailsSheetComponent,
        ResourceManagementComponent,
        ResourceManagerControlsComponent,
        SharedActionLogComponent,
        MenuSideLogUserResourceEventsComponent,
        BlockLoaderComponent,
        CourseManagerCoreComponent,
        CourseManagerCoreSecodaryControlsComponent,
        CourseManagerCoreItemsComponent,
        CourseManagerCoreAddMaterialComponent,
        CourseManagerCoreAddSurveyComponent,
        CourseManagerCoreAddTestComponent,
        TestManagerCoreComponent,
        SurveyManagerCoreComponent,
        SectionsViewerSheetComponent,
        CasesSelectComponent,
        MenuSideDisplayMediaComponent,
        SharedLogUsersTabsComponent,
        MultipleStringInputsComponent,
        ShipsWithAddingHandlerComponent,
        NotesResourceListingComponent,
        NotesResourceListingDetailsComponent,
        NotesResourceListingSharedComponent,
        NotesResourceListingOwnedComponent,
        ActivityLogSxComponent,
        ActivityLogSxBoxComponent,
        BrowseSectionComponent,
        BrowseSectionListComponent,
        CollectionSelectComponent,
        CollectionBrowseBottomSheetComponent,
        CollectionsListingSelectComponent,
        CollectionItemSheetComponent,
        CollectionItemBuilderComponent,
        CollectionItemSheetBrowseChildrenComponent,
        CollectionsChildrenSelectComponent,
        CollectionItemChildrenListComponent,
        SidebarMenuItemSubmenuComponent,
        MenuSideManageWidgetsComponent,
        MenuSideQuestionExtraInfoComponent,
        MenuSideDownloadComponent,
        MenuSideAddEducatorComponent,
        ResourceEducatorHandlerComponent,
        EventManagerCoreComponent,
        EventManagerCoreItemsComponent,
        EventManagerCoreSecondaryControlsComponent,
        MenuSideResourceManagersComponent,
        DateTimeDisplayedCalendarPickerComponent,
        GeoLocationControlComponent,
        ResourcePublisherHandlerComponent,
        GeneralChatGPTComponent,
        MenuSideAiPrompterComponent,
        AiPrompterManagerComponent,
        ItemTranslatorComponent,
        MenuSideCreateLinkMaterialComponent,
        TableFilterExpansionPanelComponent,
        MenuSideAddTaskComponent,
        MenuSideMediaUploadImgComponent,
        MenuSideMailTextBlockComponent,
        SharedActionLogOverallComponent,
        ChipSelectComponent,
        CalendarDisplayWithDatesComponent,
        AddMaterialAiComponent,
        GeneralQuotesListingComponent,
        QuotesManagerComponent,
        PostsAssignmentComponent,
        VtqSlidersComponent,
        MenuSideVideoToQuizComponent,
        MenuSideGenerateAIComponent,
        MenuSideShowAllQuotesComponent,
        MenuSideEventRegComponent,
        CircleGaugeComponent,
        VideoLinkControlComponent,
        MenuSideShowQuoteeQuotesComponent,
        QuotesSliderComponent,
        TaskListingGeneralComponent,
        TaskNotesComponent,
        TaskListingChildComponent,
        MenuSideAddCustomerComponent,
        MultiRowsTable,
        ConnectionListingGeneralComponent,
        SharedHrNotesComponent,
        SharedHrNotesCommentsComponent,
        CommunicationsComponent,
        TaskTagsHandlerComponent,
        MenuSideAddNextContactComponent,
        TaskNotesListComponent,
        DateTimePickerItemComponent,
        MenuSideAddHrNoteComponent,
        ItemChangeOnTheFlyComponent,
        SignatureChangeOnTheFlyComponent,
        TableTagsOnTheFlyComponent,
        NotesContextComponent,
        CustomerChipSelectComponent,
        NotesContextDisplayComponent,
        UserPerosonalSettingsAndLanguageComponent,
        UserPerosonalSettingsFavCategoriesComponent,
        UserPerosonalSettingsPersonalInterestComponent,
        ChipsWithAddHandlerComponent,
        MenuSideCreateGroupComponent,
        MenuSideAddMediaFileComponent,
        MediaListingGeneralComponent,
        FilesListingGeneralComponent,
        MenuSideCreateResourceComponent,
        MenuSideMediaLibraryComponent,
        FilesNamesListingComponent,
        ColorsPickerInputFieldComponent,
        PurchaseManagerComponent,
        MenuSideFormBuilderComponent,
        MenuSideCustomFieldsListingComponent,
        CustomFieldsComponent,
        ResourceManadatorySheetComponent,
        UsersSelectToolsComponent,
        UsersSelectReminderComponent,
        UsersSelectHasOccasionsComponent,
        ResourcesCompetenceListComponent,
        MenuSideQuestionReviewComponent,
        FormativeEvaluationComponent,
        AddFormativeEvaluationComponent,
        MaterialsManagerCoreComponent,
        UserSelectWithCheckEditComponent,
        CourseManagerCoreMaterialManagerComponent,
        ResourceTitleWithIconComponent,
        MenuSideRelatedResourcesComponent,
        ResourcesListingUserOccasionsComponent,
        GeneralTestResultsComponent,
        QuestionsWithAltsResultsComponent,
        QuestionsWithNoAltsResultsComponent,
        MenuSideCourseItemDoneComponent,
        CoursePlayerResultsComponent,
        CoursePlayerResultsContentComponent,
        CoursePlayerResultsQuestionsComponent,
        CoursePlayerResultsResultComponent,
        CoursePlayerResultsDiplomaComponent,
        MenuSideDateRangeComponent,
        MaterialItemsDisplayInfoComponent,
        FormativeEvaluationItemComponent,
        FormativeEvaluationBottomSheetComponent,
        MenuSideUserActionsTemplateComponent,
        MenuSideCancelCoursePlayComponent,
        MenuSideTextareaWithMultipleActionsComponent,
        MenuSideResourceEnrollmentApproveComponent,
        MenuSideResourceEnrollmentRequestComponent,
        ResourceAccessControlSheetComponent,
        ResourceAccessControlUsersComponent,
        ResourceAccessControlGroupsComponent,
        ResourcePermissionsHandlerComponent,
        ResourceDetailsSheetComponent,
        MenuSideCourseMaterialItemDoneComponent,
        MenuSideCourseItemDoneAllComponent,
        MenuSideResourceDoneAUActivityComponent,
        IframeDisplayLinkComponent,
        CheckboxWithCustomUserClickComponent,
        ResourceDetailsBaseComponent,
        ResourceTableBaseComponent,
        ResizeObserverDirective,
        ResourcesGridBaseComponent,
        ResourcesGridItemComponent,
        MenuSideDashboardColumnSettingsComponent,
        ExpansionBlockHeaderComponent,
        MessageSubComponent,
        CourseManagerCoreAddEventComponent,
        CourseManagerEventCreateComponent,
        ResourceBuilderEventComponent,
        MenuSideEventJoinComponent,
        ResourcesCompetenceListSheetComponent,
        MenuSideSendGroupEmailComponent,
        TeamGroupsSelectComponent,
        TeamGroupsUsersSelectSheetComponent,
        ResourceAccessSpecificPermissionComponent,
        MenuSideToggleCompetenceComponent,
        ResourceManagerAuthorHandlerComponent,
        UserResourcesAccessControlComponent,
        GroupResourcesAccessControlComponent,
        UserResourcesPermissionsHandlerComponent,
        UsersGroupsAccessControlSheetComponent,
        ResourcesSubGridBaseComponent,
        MenuSideManageAskAiPredefinedQsComponent,
        ResourceHistoryListingComponent,
        MenuSideQuickAddCompetenceComponent,
        TagItemChipSelectComponent,
        TagRelatedScopesListingComponent,
        BreadcrumbRoutersComponent,
        MenuSideLoginRegisterComponent,
        MenuSideAddCourseItemsComponent,
        GuestOnboardingFooterComponent,
        LanguageViewierComponent,
        MenuSideEmailMultipleUsersComponent,
        K3NotesListingComponent,
        GeneralTestOccasionsResultsComponent,
        MenuSideValidateSignoffComponent,
        MenuSideValidationFeedbackComponent,
        ResourceBuilderAiLoaderPanelComponent,
        ScormManagerCoreComponent,
        StationsSelectInGroupComponent,
        GroupsSelectWithStationsComponent,
    ],
    providers: [
        { provide: MAT_DATE_FORMATS, useValue: APP_MOMENT_DATE_FORMATS },
        { provide: MAT_COLOR_FORMATS, useValue: CUSTOM_MAT_COLOR_FORMATS },
        // { provide: MAT_DATE_LOCALE, useValue: 'sv' },
        // { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
        { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
        EditorTemplateService,
        EditorMergeFieldService,
    ],
})
export class SharedComponentsModule {}
